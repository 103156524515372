import { SettlementOverallFormFields, UpdateSettlementOverallDTO } from 'types';
import { parseFormattedBankAccountNumberIntoBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseFormattedBankAccountNumberIntoBankAccountNumber';

export const parseSettlementOverallFormFieldsIntoUpdateSettlementOverallDTO = (
    settlementOverallFormFields: SettlementOverallFormFields,
): UpdateSettlementOverallDTO => ({
    workingDays: settlementOverallFormFields.workingDays,
    recipientName: settlementOverallFormFields.recipientName || null,
    bankAccountNumber: settlementOverallFormFields.bankAccountNumber
        ? parseFormattedBankAccountNumberIntoBankAccountNumber(
              settlementOverallFormFields.bankAccountNumber,
          )
        : null,
    paymentDeadline: settlementOverallFormFields.paymentDeadline,
});
