import { useMemo } from 'react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { JournalsFilters } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts, journalTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type JournalsPageNetworkManageProps = {
    filtersValues: JournalsFilters;
    onJournalArchiveRequestFinish: () => void;
};

export const useJournalsPageNetworkManage = ({
    filtersValues,
    onJournalArchiveRequestFinish,
}: JournalsPageNetworkManageProps) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const filters = useMemo<JournalsFilters>(() => {
        const { status, type, search, fromYear, toYear } = filtersValues;
        const journalFilters: JournalsFilters = {};
        if (type) {
            journalFilters.type = type;
        }
        if (status) {
            journalFilters.status = status;
        }
        if (search) {
            journalFilters.search = search;
        }
        if (fromYear && toYear) {
            journalFilters.fromYear = fromYear;
            journalFilters.toYear = toYear;
        }
        return journalFilters;
    }, [filtersValues]);

    const {
        data: journalsData,
        isInitialLoading: isJournalsDataInitialLoading,
        isRefetching: isJournalsDataRefetching,
        isError: isJournalsDataError,
    } = useAppQuery('JOURNALS', [filters], () => StaffHTTPService.journals.getJournals(filters), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const { mutate: archiveJournal, isLoading: isArchiveJournalLoading } = useAppMutation(
        StaffHTTPService.journals.archiveJournal,
        {
            key: ['ARCHIVE_JOURNAL'],
            onSuccess: async (_, variables) => {
                onJournalArchiveRequestFinish();
                // variables is JournalId
                await queryClient.invalidateQueries([institutionId, 'JOURNAL', variables]);
                displaySuccess(journalTexts.journals.archiveJournalSuccess);
            },
            onError: () => {
                onJournalArchiveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['JOURNALS'], ['LESSONS_JOURNALS'], ['MAIL_THREAD_RECEIVERS']],
        },
    );

    return {
        journalsData,
        isArchiveJournalLoading,
        isJournalsDataInitialLoading,
        isJournalsDataRefetching,
        isJournalsDataError,
        archiveJournal,
    };
};
