import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SorterResult, SortOrder } from 'antd/es/table/interface';
import { isEqual } from 'lodash';

const SORT_BY_KEY = 'sortBy';
const SORT_DIRECTION_KEY = 'sortDirection';

export const useTableSorter = <RecordType>() => {
    const [prevSorter, setPrevSorter] = useState<
        SorterResult<RecordType> | SorterResult<RecordType>[]
    >();
    const [searchParams, setSearchParams] = useSearchParams();

    const sortBySearchParam = searchParams.get(SORT_BY_KEY) || undefined;
    const sortDirectionSearchParam =
        (searchParams.get(SORT_DIRECTION_KEY) as SortOrder) || undefined;

    const handleSorterChange = (sorter: SorterResult<RecordType> | SorterResult<RecordType>[]) => {
        if (Array.isArray(sorter) || isEqual(prevSorter, sorter)) {
            return;
        }
        const { order: sortDirection, field: sortBy } = sorter;

        setPrevSorter({ field: sortBy, order: sortDirection });

        if (sortBy && sortDirection) {
            setSearchParams(
                (prevSearchParams) => {
                    prevSearchParams.set(SORT_BY_KEY, sortBy.toString());
                    prevSearchParams.set(SORT_DIRECTION_KEY, sortDirection);

                    return prevSearchParams;
                },
                { replace: true },
            );
        } else {
            setSearchParams((prevSearchParams) => {
                prevSearchParams.delete(SORT_BY_KEY);
                prevSearchParams.delete(SORT_DIRECTION_KEY);
                return prevSearchParams;
            });
        }
    };

    return {
        sortData: { sortDirection: sortDirectionSearchParam, sortBy: sortBySearchParam },
        handleSorterChange,
    };
};
