import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FiltersParams, Pagination } from 'types';
import { SEARCH_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { DEFAULT_PAGE, PAGE_PARAM_KEY } from 'consts/pagination';
import { NO_FILTER_VALUE } from 'consts/filters/common/defaultFilterValue';
import { useSearchFilter } from '../useSearchFilter/useSearchFilter';

export type PageFilter<FilterKey extends string> = {
    name: FilterKey;
    type: 'SINGLE_VALUE' | 'ARRAY' | 'ARRAY_WITH_SPLIT_VALUES';
};

type PageFiltersProps<FilterKey extends string> = {
    filters: PageFilter<FilterKey>[];
    currentPage?: Pagination['currentPage'];
};

export const usePageFilters = <FilterKey extends string>({
    filters,
    currentPage,
}: PageFiltersProps<FilterKey>) => {
    const { searchFilterValue, handleSearchInput } = useSearchFilter({
        currentPage,
    });
    const [searchParams, setSearchParams] = useSearchParams();

    const replace = currentPage === undefined || currentPage === DEFAULT_PAGE;

    const handleClearFilters = (clearSearchFilter?: boolean) => {
        setSearchParams((prevSearchParams) => {
            if (clearSearchFilter) {
                prevSearchParams.delete(SEARCH_FILTER_KEY);
            }
            filters.forEach((filter) => prevSearchParams.delete(filter.name));
            prevSearchParams.set(PAGE_PARAM_KEY, DEFAULT_PAGE);
            return prevSearchParams;
        });
    };

    const handleClearFilter = (filterKey: FilterKey) => {
        setSearchParams((prevSearchParams) => {
            prevSearchParams.delete(filterKey);
            prevSearchParams.set(PAGE_PARAM_KEY, DEFAULT_PAGE);
            return prevSearchParams;
        });
    };

    const updateFilter = (filterKey: FilterKey, value: string | string[]) => {
        setSearchParams(
            (prevSearchParams) => {
                if (Array.isArray(value)) {
                    prevSearchParams.delete(filterKey);
                    value.forEach((filterValue) => {
                        if (filterValue.includes('/')) {
                            const [splittedKey, splittedValue] = filterValue.split('/');
                            prevSearchParams.delete(splittedKey, splittedValue);
                            return prevSearchParams.append(splittedKey, splittedValue);
                        }
                        return prevSearchParams.append(filterKey, filterValue);
                    });
                } else {
                    prevSearchParams.set(filterKey, value);
                }
                prevSearchParams.set(PAGE_PARAM_KEY, DEFAULT_PAGE);
                return prevSearchParams;
            },
            { replace },
        );
    };

    const handleChangeFilter = (
        filterKey: FilterKey,
        value: string | string[] | undefined,
        withNoFilterAsNotSelectedFilterValue?: boolean,
    ) => {
        if (!value || !value.length) {
            if (withNoFilterAsNotSelectedFilterValue) {
                updateFilter(filterKey, NO_FILTER_VALUE);
            } else {
                handleClearFilter(filterKey);
            }
            return;
        }
        updateFilter(filterKey, value);
    };

    const handleChangeFilters = (
        filterValues: Partial<Record<FilterKey, string>>,
        withNoFilterAsNotSelectedFilterValue?: boolean,
    ) => {
        setSearchParams(
            (prevSearchParams) => {
                Object.keys(filterValues).forEach((filterKey) => {
                    const filterValue = filterValues[filterKey as FilterKey];
                    if (!filterValue) {
                        if (withNoFilterAsNotSelectedFilterValue) {
                            prevSearchParams.set(filterKey, NO_FILTER_VALUE);
                        } else {
                            prevSearchParams.delete(filterKey);
                        }
                    } else {
                        prevSearchParams.set(filterKey, filterValue);
                    }
                });
                prevSearchParams.set(PAGE_PARAM_KEY, DEFAULT_PAGE);
                return prevSearchParams;
            },
            { replace },
        );
    };

    const filtersParams = useMemo(
        () =>
            filters.reduce<FiltersParams>((allFiltersParams, currentFilter) => {
                const { name: filterName, type: filterType } = currentFilter;
                let paramValue =
                    filterType === 'SINGLE_VALUE'
                        ? searchParams.get(filterName)
                        : searchParams.getAll(filterName);

                if (filterType === 'ARRAY_WITH_SPLIT_VALUES' && Array.isArray(paramValue)) {
                    paramValue = paramValue.map((value) => `${filterName}/${value}`);
                }

                return { ...allFiltersParams, [filterName]: paramValue };
            }, {}),
        [filters, searchParams],
    );

    return {
        filtersParams,
        searchFilterValue,
        handleChangeFilter,
        handleChangeFilters,
        handleClearFilter,
        handleClearFilters,
        handleSearchInput,
    };
};
