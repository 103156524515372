import { Rule } from 'rc-field-form/es/interface';
import { AppFormItem, AppInput } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { commonTexts } from 'consts/text';
import { emailRegExp } from 'consts/regexps';

type EmailInputElementProps = {
    disabled?: boolean;
    extraOnDisabled?: string;
    withEmailValidatingRules?: boolean;
};

export const EmailInputElement = ({
    disabled = false,
    extraOnDisabled,
    withEmailValidatingRules = true,
}: EmailInputElementProps) => {
    const validationRules: Rule[] = [{ required: true, message: formElementRequiredText }];
    if (withEmailValidatingRules) {
        validationRules.push(
            {
                type: 'email',
                message: commonTexts.errorMessages.invalidEmail,
            },
            {
                pattern: emailRegExp,
                message: commonTexts.errorMessages.emailIllegalCharacters,
            },
        );
    }

    return (
        <AppFormItem
            disabled={disabled}
            label={commonTexts.inputLabels.email}
            name={formFields.email}
            rules={validationRules}
            extra={disabled && extraOnDisabled}
        >
            <AppInput autoComplete="email" disabled={disabled} />
        </AppFormItem>
    );
};
