import { DatePickerWithArrows, ErrorDisplay, LoadingDisplay } from 'components/molecules';
import { AppGoBackButton, AppText } from 'components/atoms';
import { commonTexts, settlementTexts } from 'consts/text/index';
import { FULL_MONTH_FORMAT } from 'consts/dateFormats';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import dayjs from 'dayjs';
import { Header } from 'components/templates/child/ChildDetails/components/Header/Header';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useOutlet, useParams, useSearchParams } from 'react-router-dom';
import { Child, ChildId, ChildSettlementId, CostAllocationId, PaymentId } from 'types';
import { useEffect, useState } from 'react';
import { getFullName } from 'utils/getFullName';
import { appRoutes } from 'consts/paths/paths';
import { BalanceTag } from 'components/molecules/BalanceTag/BalanceTag';
import { Row } from 'antd';
import { RemoveModal } from 'components/organisms';
import { ChildAddEditBillModal } from 'components/templates/settlement/ChildAddBillModal/ChildAddEditBillModal';
import { SummaryText } from 'components/atoms/SummaryText/SummaryText';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { ChildSettlementPaymentTable } from 'components/templates/settlement/ChildSettlementPaymentTable/ChildSettlementPaymentTable';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ChildSettlementItem } from 'components/templates/settlement/ChildSettlementItem/ChildSettlementItem';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { FiltersContainer, StyledRow } from './ChildSettlementDetails.styled';

export const ChildSettlementDetails = () => {
    const outlet = useOutlet();
    const { displaySuccess, displayError } = useSimpleNotification();

    const [removeObject, setRemoveObject] = useState<{
        removeTarget: 'costAllocation' | 'splitPayment';
        removeId: ChildSettlementId | CostAllocationId;
    } | null>(null);

    const pageRoute = appRoutes.app.settlement.children.pickedChild.details.base;
    const [_, setSearchParams] = useSearchParams();
    const { childId } = useParams<{ childId: ChildId }>();

    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });
    const [addBillModalOpen, setAddBillModalOpen] = useState<{
        childId: ChildId;
        transactionId: PaymentId | null;
    } | null>(null);

    const selectedDate = dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs();
    const selectedYear = selectedDate.year();
    const selectedMonth = getMonthFromDayjsMonth(selectedDate.month());

    const {
        data: childSettlementData,
        isFetching: isChildSettlementFetching,
        isError: isChildSettlementError,
    } = useAppQuery(
        'CHILD_SETTLEMENT',
        [childId, selectedMonth, selectedYear],
        () =>
            StaffHTTPService.settlements.getChildSettlement({
                childId: childId as ChildId,
                month: selectedMonth,
                year: selectedYear,
            }),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const {
        data: childCostSplits,
        isFetching: isChildCostSplitsFetching,
        isError: isChildCostSplitsError,
    } = useAppQuery(
        'CHILD_SETTLEMENT_COST_SPLIT',
        [childId, childSettlementData?.id],
        () =>
            StaffHTTPService.settlements.getChildCostAllocations({
                settlementId: childSettlementData?.id as ChildSettlementId,
            }),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
            enabled: !!childSettlementData?.id,
        },
    );

    useEffect(() => {
        if (!childSettlementData) {
            return;
        }
        const params = new URLSearchParams(window.location.search);
        params.set(
            pageRoute,
            getFullName(
                childSettlementData?.child?.firstName,
                childSettlementData?.child?.lastName,
            ),
        );
        setSearchParams(params, { replace: true });
    }, [childSettlementData, pageRoute, setSearchParams]);

    const onRemoveCostAllocationSuccess = () => {
        displaySuccess(settlementTexts.children.costAllocation.removeSuccess);
        setRemoveObject(null);
    };

    const onRemoveCostAllocationError = () => {
        displayError(commonTexts.errorMessages.unknown);
    };

    const { mutate: removeCostAllocation, isLoading: isRemoveCostAllocationLoading } =
        useAppMutation(StaffHTTPService.settlements.removeCostAllocation, {
            key: ['REMOVE_COST_ALLOCATION'],
            onSuccess: onRemoveCostAllocationSuccess,
            onError: onRemoveCostAllocationError,
            invalidateQueryKeys: [
                ['COST_ALLOCATION'],
                ['CHILD_SETTLEMENT', childId],
                ['CHILD_SETTLEMENT_COST_SPLIT', childId],
            ],
        });

    const filters = (
        <FiltersContainer justify="space-between" align="bottom">
            <DatePickerWithArrows
                label={commonTexts.actionLabels.selectMonth}
                value={selectedDate}
                picker="month"
                format={FULL_MONTH_FORMAT}
                onChange={handleChangeDateFilter}
                onArrowButtonClick={(arrowButtonType) =>
                    handleClickNextOrPrevDate(arrowButtonType, 'month')
                }
            />
            <SummaryText
                text={
                    childSettlementData
                        ? getFormattedPrice(childSettlementData?.totalCost)
                        : getFormattedPrice(0)
                }
            />
        </FiltersContainer>
    );

    if (isChildSettlementFetching || isChildCostSplitsFetching) {
        return <LoadingDisplay />;
    }

    if (!childSettlementData || isChildSettlementError) {
        return <ErrorDisplay />;
    }

    const removeTitle =
        removeObject?.removeTarget === 'costAllocation'
            ? settlementTexts.children.costAllocationRemove
            : settlementTexts.children.paymentRemove;

    const onRemoveCancel = () => {
        setRemoveObject(null);
    };

    const onRemoveOk = () => {
        if (removeObject?.removeTarget === 'costAllocation' && removeObject?.removeId) {
            removeCostAllocation({ costAllocationId: removeObject.removeId as CostAllocationId });
        }
    };

    return (
        <>
            {outlet}
            <RemoveModal
                open={!!removeObject}
                title={removeTitle}
                onOk={onRemoveOk}
                onCancel={onRemoveCancel}
                confirmActionLoading={isRemoveCostAllocationLoading}
            />
            <ChildAddEditBillModal
                mode={addBillModalOpen?.transactionId ? 'edit' : 'add'}
                childId={addBillModalOpen?.childId}
                transactionId={addBillModalOpen?.transactionId}
                onCancel={() => setAddBillModalOpen(null)}
            />
            <Row justify="space-between" align="middle">
                <AppGoBackButton />
            </Row>
            <Header
                {...(childSettlementData?.child as Child)}
                canManageChildAvatar={false}
                httpMethod={{
                    ...StaffHTTPService.files,
                    ...StaffHTTPService.children.profilePhoto,
                }}
                additionalElement={
                    <StyledRow>
                        <AppText marginLeft={0}>{settlementTexts.children.details.balance}</AppText>
                        <BalanceTag amount={childSettlementData.balance} />
                    </StyledRow>
                }
            />
            <ChildSettlementPaymentTable
                onAddClick={() =>
                    setAddBillModalOpen({ childId: childId as ChildId, transactionId: null })
                }
                onEditClick={(transactionId: PaymentId) =>
                    setAddBillModalOpen({ childId: childId as ChildId, transactionId })
                }
            />
            {filters}
            <ChildSettlementItem
                removeObject={setRemoveObject}
                costSplits={childCostSplits}
                isChildCostSplitsError={isChildCostSplitsError}
                {...childSettlementData}
            />
        </>
    );
};
