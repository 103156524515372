import { createGlobalStyle, css } from 'styled-components';
import { cellContentStyle } from 'consts/styles/cellContentStyle';

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
      .ant-table .cell-content button > div > div.ant-wave {
          box-shadow: none;
      }

      .ant-table-wrapper {
          .ant-table-container {
              border: 1px solid ${theme.colors.primary.primary5};
              overflow: hidden;
              border-radius: 10px;

              .ant-table-body,
              .ant-table-content {
                  overflow: auto !important;

                  table {
                      .ant-table-tbody {
                          .ant-table-row {
                              border-bottom: 1px solid ${theme.colors.primary.primary3};

                              &:nth-child(odd of tr.ant-table-row-level-0) {
                                  @media ${theme.sizes.mediaQueries.desktopMin} {
                                      background-color: ${theme.colors.primary.primary2};
                                  }
                              }

                              .ant-table-cell {
                                  padding: 8px;

                                  .cell-content {
                                      ${cellContentStyle}
                                  }

                                  @media ${theme.sizes.mediaQueries.desktopMin} {
                                      padding: 12px;

                                      &:first-child {
                                          padding-left: 40px;
                                      }
                                  }

                                  &.ant-table-cell-row-hover {
                                      background-color: ${theme.colors.primary.primary4};
                                  }

                                  &.ant-table-cell-fix-left {
                                      background-color: ${theme.colors.primary.primary2};
                                  }
                              }
                          }

                          .ant-table-expanded-row {
                              .ant-table-cell {
                                  padding: 0;
                                  width: 100%;
                                  .cell-content {
                                      display: flex;
                                  }
                              }
                          }
                      }
                  }
              }

              td.ant-table-column-sort {
                  background-color: ${theme.colors.primary.primary3};
                  border-bottom-color: ${theme.colors.primary.primary4};
              }

              .ant-table-expand-icon-col {
                  width: unset;
              }

              .ant-table-thead {
                  & > tr > th,
                  & > tr > td {
                      background-color: ${theme.colors.grayscale.gray1};
                  }

                  & > tr > th:before {
                      display: none;
                  }

                  th.ant-table-column-sort {
                      background-color: ${theme.colors.primary.primary5};
                      border-bottom-color: ${theme.colors.primary.primary6};
                  }

                  th.ant-table-column-has-sorters:hover {
                      background-color: ${theme.colors.primary.primary4};
                      border-bottom-color: ${theme.colors.primary.primary5};
                  }

                  .ant-table-cell {
                      background-color: unset;
                      padding: 12px;
                      border-bottom: 1px solid ${theme.colors.primary.primary3};

                      &:before {
                          display: none;
                      }

                      @media ${theme.sizes.mediaQueries.desktopMin} {
                          &:first-child {
                              border-start-start-radius: 0;
                              padding-left: 40px;
                          }
                      }

                      &:last-child {
                          border-start-end-radius: 0;
                      }

                      &.ant-table-cell-fix-left {
                          background-color: ${theme.colors.primary.primary2};
                      }
                  }
              }

              @media ${theme.sizes.mediaQueries.mobileMax} {
                  td.ant-table-column-sort {
                      background-color: inherit;
                      border-bottom-color: inherit;
                  }
              }
          }
      }

      .ant-pagination-prev.ant-pagination-disabled button.ant-pagination-item-link {
          color: ${theme.colors.grayscale.gray6};

          &:hover {
              color: ${theme.colors.grayscale.gray5};
          }
      }
  `}
`;

export const GlobalStyleWithTableHeader = createGlobalStyle`
  .ant-table-wrapper div.ant-table-container {
    border-radius: 0 0 10px 10px;
  }
`;
