import { useState } from 'react';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { AppCardList, RemoveModal } from 'components/organisms';
import { GroupJournalTopicCard, DatePickerWithArrows } from 'components/molecules';
import { Option } from 'components/atoms';
import { GroupTopicDTO, GroupTopicId } from 'types';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { commonTexts, journalTexts } from 'consts/text';
import { generatePath } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { useJournalAbilities } from 'abilities';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { institutionNonWorkingDayErrorStateConfig } from 'consts/journal/institutionNonWorkingDayErrorStateConfig';
import { useGroupJournalTopicsPageNetworkManage } from './hooks/useGroupJournalTopicsPageNetworkManage';

export const GroupJournalTopicsPage = () => {
    const [selectedToRemoveTopicId, setSelectedToRemoveTopicId] = useState<GroupTopicId | null>(
        null,
    );
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });
    const {
        groupJournalsAbilities: { checkIfCanManageTopics },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'GROUP',
    });

    const canManageTopics = checkIfCanManageTopics();

    const handleTopicRemoveRequestFinishOrCancel = () => setSelectedToRemoveTopicId(null);

    const {
        groupTopicsData,
        isGroupTopicsDataRefetching,
        isGroupTopicsDataInitialLoading,
        isRemoveGroupTopicLoading,
        groupTopicsDataError,
        removeGroupTopic,
    } = useGroupJournalTopicsPageNetworkManage({
        journalId,
        date: dateFilterValue || parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD'),
        onTopicRemoveRequestFinish: handleTopicRemoveRequestFinishOrCancel,
    });

    const handleRemoveTopicClick = (topic: GroupTopicDTO) => setSelectedToRemoveTopicId(topic.id);

    const navigateToEditTopic = (topic: GroupTopicDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.groupJournalDetails.pickedJournal.topics.edit.pickedTopic,
                { journalId, topicId: topic.id },
            ),
        );

    const handleRemoveTopicConfirm = () => {
        if (!selectedToRemoveTopicId) {
            return;
        }
        removeGroupTopic({ journalId, groupTopicId: selectedToRemoveTopicId });
    };

    const navigateToAddTopic = () =>
        statefulNavigate(
            generatePath(appPaths.app.journals.groupJournalDetails.pickedJournal.topics.add, {
                journalId,
            }),
        );

    const options: Option<GroupTopicDTO>[] = [
        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToEditTopic,
            Icon: <IconEdit />,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveTopicClick,
            Icon: <IconTrash />,
        },
    ];

    const groupTopicsDataErrorCode =
        groupTopicsDataError instanceof AxiosError
            ? groupTopicsDataError?.response?.data?.code
            : '';
    const isInstitutionNonWorkingDayError =
        groupTopicsDataErrorCode === apiErrorCodes.INSTITUTION_NON_WORKING_DAY;

    return (
        <>
            <RemoveModal
                open={!!selectedToRemoveTopicId}
                title={journalTexts.journalDetails.groupJournal.topics.topicRemoveModalTitle}
                confirmActionLoading={isRemoveGroupTopicLoading}
                onOk={handleRemoveTopicConfirm}
                onCancel={handleTopicRemoveRequestFinishOrCancel}
            />
            <AppCardList
                title={journalTexts.journalDetails.groupJournal.topics.topicsListTitle}
                addButtonLabel={
                    journalTexts.journalDetails.groupJournal.topics.topicsListAddButtonLabel
                }
                onAddButtonClick={navigateToAddTopic}
                emptyInfo={journalTexts.journalDetails.groupJournal.topics.topicsListEmptyState}
                dataSource={groupTopicsData}
                renderItem={(topic, index) => (
                    <GroupJournalTopicCard
                        index={index}
                        topic={topic}
                        options={canManageTopics ? options : undefined}
                        isOptionsLoading={isRemoveGroupTopicLoading}
                    />
                )}
                isError={!!groupTopicsDataError}
                isDataInitialLoading={isGroupTopicsDataInitialLoading}
                isDataRefetching={isGroupTopicsDataRefetching}
                isHiddenAddButton={!canManageTopics || isInstitutionNonWorkingDayError}
                errorStateConfig={
                    isInstitutionNonWorkingDayError
                        ? institutionNonWorkingDayErrorStateConfig
                        : undefined
                }
                filters={
                    <DatePickerWithArrows
                        value={
                            dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs()
                        }
                        onChange={handleChangeDateFilter}
                        onArrowButtonClick={handleClickNextOrPrevDate}
                    />
                }
            />
        </>
    );
};
