type JournalSchoolYearsDisplayProps = {
    schoolYears: string[];
    fromYearFilter?: number;
    toYearFilter?: number;
};

const SCHOOL_YEAR_SEPARATOR = '/';

export const JournalSchoolYearsDisplay = ({
    schoolYears,
    fromYearFilter,
    toYearFilter,
}: JournalSchoolYearsDisplayProps) => {
    if (fromYearFilter && toYearFilter) {
        if (fromYearFilter === toYearFilter) {
            return schoolYears.join(', ');
        }
        return schoolYears
            .filter((schoolYear) => {
                const [startYear, finishYear] = schoolYear.split(SCHOOL_YEAR_SEPARATOR);
                return fromYearFilter <= Number(startYear) && toYearFilter >= Number(finishYear);
            })
            .join(', ');
    }
    return schoolYears.join(', ');
};
