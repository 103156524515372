import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { parseInstitutionBaseDataIntoSelectOptionType } from 'utils/parsers/institution/parseInstitutionBaseDataIntoSelectOptionType';
import { SchoolIcon } from 'assets';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { currentInstitutionAtom } from 'jotaiAtoms/currentInstitution';
import { writeLocalStorageInstitutionId } from 'services/localStorage/institutionId';
import { isLoggedUserDrawerMenuOpenAtom } from 'jotaiAtoms/isLoggedUserDrawerMenuOpenAtom';
import { AppSelect } from 'components/atoms/CommonAppComponents';
import { appPaths } from 'consts/paths/paths';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { DropdownRender } from './components/DropdownRender';

type InstitutionDropdownProps = {
    isSearchEnabled?: boolean;
};

export const InstitutionDropdown = ({ isSearchEnabled }: InstitutionDropdownProps) => {
    const [searchValue, setSearchValue] = useState('');
    const userDetails = useAtomValue(userDetailsAtom);
    const setIsLoggedUserDrawerMenuOpen = useSetAtom(isLoggedUserDrawerMenuOpenAtom);
    const [currentInstitution, setCurrentInstitution] = useAtom(currentInstitutionAtom);
    const navigate = useNavigate();

    const userInstitutions = userDetails?.institutions;

    const onChange = (pickedInstitutionId: string) => {
        if (!userInstitutions) {
            return;
        }
        const pickedInstitution = userInstitutions.find(({ id }) => id === pickedInstitutionId);
        setCurrentInstitution(pickedInstitution || userInstitutions[0]);
        writeLocalStorageInstitutionId(pickedInstitution?.id || userInstitutions[0].id);
        setIsLoggedUserDrawerMenuOpen(false);
        navigate(appPaths.app.base);
    };

    const institutionsToPick = useMemo(() => {
        if (!userInstitutions) {
            return [];
        }
        const filteredInstitutions = userInstitutions.filter(({ name }) =>
            name.toLowerCase().trim().includes(searchValue.toLowerCase().trim()),
        );
        return filteredInstitutions.map(parseInstitutionBaseDataIntoSelectOptionType);
    }, [searchValue, userInstitutions]);

    const dropdownRender = (menu: React.ReactElement) => (
        <DropdownRender
            menu={menu}
            onSearchInputChange={
                isSearchEnabled ? debounce(setSearchValue, DEBOUNCE_DELAY) : undefined
            }
        />
    );

    return (
        <AppSelect
            Icon={SchoolIcon}
            value={currentInstitution.id}
            onChange={onChange}
            options={institutionsToPick}
            dropdownRender={dropdownRender}
            allowClear={false}
            variant="borderless"
        />
    );
};
