import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { childrenTexts, commonTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';

export const childArchiveErrorParser = (error: unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.CHILD_NOT_SETTLED_ARCHIVE) {
        return childrenTexts.archiveChildWthNonZeroBalanceErrorMessage;
    }

    return commonTexts.errorMessages.actionExecution;
};
