import { AppFormItem, AppFormItemProps, AppSelect } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { timetableTexts } from 'consts/text';
import { Repetition, SelectOptionType } from 'types';

const options: SelectOptionType<Repetition>[] = [
    {
        label: timetableTexts.lessons.lessonsRepetitionLabels.everyDay,
        value: 'EVERY_DAY',
    },
    {
        label: timetableTexts.lessons.lessonsRepetitionLabels.workingDays,
        value: 'WORKING_DAYS',
    },
    {
        label: timetableTexts.lessons.lessonsRepetitionLabels.everyWeek,
        value: 'EVERY_WEEK',
    },
    {
        label: timetableTexts.lessons.lessonsRepetitionLabels.everyTwoWeeks,
        value: 'EVERY_2_WEEKS',
    },
    {
        label: timetableTexts.lessons.lessonsRepetitionLabels.everyMonth,
        value: 'EVERY_MONTH',
    },
];

type RepetitionPickerElementProps = AppFormItemProps & {
    disabled?: boolean;
};

export const RepetitionPickerElement = ({ disabled, ...props }: RepetitionPickerElementProps) => (
    <AppFormItem
        label={timetableTexts.lessons.lessonsRepetition}
        name={formFields.repetition}
        initialValue={options[0].value}
        {...props}
    >
        <AppSelect disabled={disabled} options={options} />
    </AppFormItem>
);
