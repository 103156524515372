import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { ChildSettlementId } from 'types';

type ParentSettlementPageNetworkManageProps = {
    selectedMonth: number;
    selectedYear: number;
};

export const useParentSettlementPageNetworkManage = ({
    selectedMonth,
    selectedYear,
}: ParentSettlementPageNetworkManageProps) => {
    const {
        data: childMonthlySettlementData,
        isInitialLoading: isChildMonthlySettlementInitialFetching,
        isRefetching: isChildMonthlySettlementRefetching,
        isError: isChildMonthlySettlementError,
    } = useAppQuery(
        'CHILD_SETTLEMENT',
        [selectedMonth, selectedYear],
        () =>
            ParentHTTPService.settlements.getMonthlySettlement({
                month: selectedMonth,
                year: selectedYear,
            }),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const {
        data: childCostSplitsData,
        isInitialLoading: isChildCostSplitsInitialFetching,
        isRefetching: isChildCostSplitsRefetching,
        isError: isChildCostSplitsError,
    } = useAppQuery(
        'CHILD_SETTLEMENT_COST_SPLIT',
        [childMonthlySettlementData?.id],
        () =>
            ParentHTTPService.settlements.getCostAllocations(
                childMonthlySettlementData?.id as ChildSettlementId,
            ),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
            enabled: !!childMonthlySettlementData?.id,
        },
    );

    return {
        childMonthlySettlementData,
        childCostSplitsData,
        isChildMonthlySettlementInitialFetching,
        isChildCostSplitsInitialFetching,
        isChildMonthlySettlementRefetching,
        isChildCostSplitsRefetching,
        isChildMonthlySettlementError,
        isChildCostSplitsError,
    };
};
