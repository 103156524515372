import { PageBaseLayout } from 'components/layouts';
import { commonTexts } from 'consts/text/index';
import { TimetableSchedule } from 'components/templates';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useMemo } from 'react';
import { TimetableScheduleData } from 'components/templates/timetable/TimetableSchedule/TimetableSchedule.types';
import { getTimetableScheduleFromTimetable } from 'utils/getTimetableScheduleFromTimetable';
import { DatePickerArrowButtonType, DatePickerWithArrows } from 'components/molecules';
import { customWeekStartEndFormat } from 'utils/customWeekStartEndFormat';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import dayjs from 'dayjs';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

export const IndividualSchedulePage = () => {
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });

    const selectedDate = dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs();
    const parsedSelectedDate = parseDayjsDateIntoString(selectedDate, 'YYYY-MM-DD');

    const {
        data: individualScheduleData,
        isInitialLoading: isIndividualScheduleDataInitialLoading,
        isError: isIndividualScheduleDataError,
    } = useAppQuery(
        'INDIVIDUAL_SCHEDULE',
        [parsedSelectedDate],
        () => ParentHTTPService.schedules.getIndividualSchedule(parsedSelectedDate),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const scheduleData = useMemo<TimetableScheduleData | null>(() => {
        if (!individualScheduleData) {
            return null;
        }

        return getTimetableScheduleFromTimetable(individualScheduleData, selectedDate);
    }, [individualScheduleData, selectedDate]);

    return (
        <PageBaseLayout
            headerContent={
                <DatePickerWithArrows
                    picker="week"
                    format={customWeekStartEndFormat}
                    value={dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs()}
                    onChange={handleChangeDateFilter}
                    onArrowButtonClick={(arrowButtonType: DatePickerArrowButtonType) =>
                        handleClickNextOrPrevDate(arrowButtonType, 'week')
                    }
                />
            }
            emptyStateData={{ emptyInfo: commonTexts.emptyInfo.noLessons }}
            isDataInitialLoading={isIndividualScheduleDataInitialLoading}
            isError={isIndividualScheduleDataError}
            isEmpty={!individualScheduleData}
        >
            {scheduleData && <TimetableSchedule timetableSchedule={scheduleData} noPreview />}
        </PageBaseLayout>
    );
};
