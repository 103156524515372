import { useContext, useMemo } from 'react';
import { AppFormContext } from 'contexts/AppFormContext';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { ChildPickerElement, ChildPickerElementProps } from 'components/molecules';
import { DateRange, JournalId } from 'types';
import { parseChildIntoSelectOptionType } from 'utils/parsers/child/parseChildIntoSelectOptionType';
import { journalTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type JournalChildPickerProps = Pick<
    ChildPickerElementProps,
    'label' | 'multiple' | 'optional' | 'disabled'
> &
    DateRange & {
        journalId: JournalId;
    };

export const JournalChildPicker = ({
    journalId,
    label,
    dateFrom,
    dateTo,
    multiple,
    optional,
    disabled,
}: JournalChildPickerProps) => {
    const { formError, setFormError } = useContext(AppFormContext);

    const isChildrenFetchingEnabled = !!(journalId && dateFrom && dateTo);

    const {
        data: childrenData,
        isFetching: isChildrenDataFetching,
        isError: isChildrenDataError,
    } = useAppQuery(
        'JOURNAL_CHILDREN',
        [journalId, dateFrom, dateTo],
        () =>
            StaffHTTPService.journals.children.getJournalChildren(journalId, { dateFrom, dateTo }),
        {
            enabled: isChildrenFetchingEnabled,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useDisplayFetchDataError({
        isFetching: isChildrenDataFetching,
        isError: isChildrenDataError,
        actualError: formError,
        errorMessage: journalTexts.journalDetails.common.errorMessages.fetchingChildrenData,
        setError: setFormError,
    });

    const childrenOptions = useMemo(() => {
        if (!childrenData) {
            return [];
        }
        return childrenData.map(parseChildIntoSelectOptionType);
    }, [childrenData]);

    return (
        <ChildPickerElement
            label={label}
            options={childrenOptions}
            multiple={multiple}
            optional={optional}
            hasOptionalText={optional}
            loading={isChildrenDataFetching}
            disabled={disabled}
        />
    );
};
