import { RemoteLessonAddUpdateDTO, RemoteLessonsFormFields } from 'types';
import { parseDayjsDateIntoString } from '../dateTime/parseDayjsDateIntoString';

export const parseRemoteLessonsFormFieldsIntoRemoteLessonCreate = (
    remoteLessonFormFields: RemoteLessonsFormFields,
): RemoteLessonAddUpdateDTO => {
    const {
        date,
        homeworkDeadline,
        name,
        description,
        homeworkDescription,
        userIds,
        attachments,
        childIds,
    } = remoteLessonFormFields;
    return {
        name,
        date: parseDayjsDateIntoString(date, 'YYYY-MM-DD'),
        employeeUserIds: userIds,
        childIds,
        description,
        homework: homeworkDescription || undefined,
        homeworkDeadline: homeworkDeadline
            ? parseDayjsDateIntoString(homeworkDeadline, 'YYYY-MM-DD')
            : null,
        attachments,
    };
};
