import { generatePath, useNavigate } from 'react-router-dom';
import { AddChildFormFields, StepsFormAnswers } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { appPaths } from 'consts/paths/paths';
import { parseChildFormFieldsIntoAddChildDTO } from 'utils/parsers/child/parseChildFormFieldsIntoAddChildDTO';
import { ChildAddStepsForm } from 'components/templates';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { childrenTexts } from 'consts/text/index';
import { useState } from 'react';
import { ChildAddDiscountModal } from '../ChildAddDiscountModal';

export const ChildAddPage = () => {
    const navigate = useNavigate();
    const { displaySuccess } = useSimpleNotification();
    const [discountModalOpen, setDiscountModalOpen] = useState(false);

    const onSuccess = () => {
        displaySuccess(childrenTexts.addChildSuccessMessage);
        setDiscountModalOpen(true);
    };

    const onRejectAddDiscountClick = () => {
        navigate(appPaths.app.children.base, { replace: true });
    };

    const { mutate, isLoading, error, data } = useAppMutation(StaffHTTPService.children.postChild, {
        key: ['ADD_CHILD'],
        onSuccess,
        invalidateQueryKeys: [['CHILDREN'], ['MAIL_THREAD_RECEIVERS'], ['CHILD_LIMIT']],
    });

    const onConfirmAddDiscountClick = () => {
        navigate(
            generatePath(appPaths.app.children.details.pickedChild['add-discount'].base, {
                childId: data?.data.child?.id,
            }),
            {
                replace: true,
            },
        );
    };

    const onFinish = (stepsData: StepsFormAnswers) => {
        const { childAddStep1, childAddStep2, childAddStep3 } = stepsData;
        mutate(
            parseChildFormFieldsIntoAddChildDTO({
                ...childAddStep1,
                ...childAddStep2,
                ...childAddStep3,
            } as AddChildFormFields),
        );
    };

    return (
        <>
            <ChildAddDiscountModal
                open={discountModalOpen}
                onConfirm={onConfirmAddDiscountClick}
                onReject={onRejectAddDiscountClick}
            />
            <ChildAddStepsForm isLoading={isLoading} axiosError={error} onFinish={onFinish} />
        </>
    );
};
