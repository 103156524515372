import dayjs from 'dayjs';
import { FiltersParams, RemoteLessonsFilters } from 'types';
import { remoteLessonFilterFields } from 'consts/filters/remoteLesson/remoteLessonFilterFields';
import { isBeforeSchoolYear } from 'utils/isBeforeSchoolYear';

export const parseFilterParamsIntoRemoteLessonsFilters: (
    filterParams: FiltersParams,
) => RemoteLessonsFilters = (filterParams) => {
    const currentDate = dayjs();
    const currentYear = currentDate.year();
    const isBeforeNewSchoolYear = isBeforeSchoolYear(currentDate, currentYear);

    const [startYearDefaultValue, endYearDefaultValue] = isBeforeNewSchoolYear
        ? [currentYear - 1, currentYear]
        : [currentYear, currentYear + 1];

    return {
        startYear: filterParams[remoteLessonFilterFields.startYear]
            ? Number(filterParams[remoteLessonFilterFields.startYear])
            : startYearDefaultValue,
        endYear: filterParams[remoteLessonFilterFields.endYear]
            ? Number(filterParams[remoteLessonFilterFields.endYear])
            : endYearDefaultValue,
    };
};
