import styled, { css } from 'styled-components';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { AppText } from 'components/atoms';

type ChildTransactionDescriptionDisplayProps = { description: string | null };

const DescriptionText = styled(AppText)`
    ${({ theme }) => css`
        width: 200px;

        @media (${theme.sizes.mediaQueries.tabletMax}) {
            width: 130px;
        }

        @media (${theme.sizes.mediaQueries.mobileMax}) {
            width: 100px;
        }
    `}
`;

export const ChildTransactionDescriptionDisplay = ({
    description,
}: ChildTransactionDescriptionDisplayProps) => (
    <DescriptionText
        ellipsis={{
            tooltip: {
                title: description,
            },
        }}
        margin={0}
    >
        {description || EMPTY_VALUE_LABEL}
    </DescriptionText>
);
