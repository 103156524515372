import { LessonFormFields, UpdateLessonDTO } from 'types';
import { parseDayjsTimeIntoHoursMinutesFormat } from 'utils/parsers/dateTime/parseDayjsTimeIntoHoursMinutesFormat';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

export const parseLessonFormFieldsIntoUpdateLessonDTO: (
    lessonFormFields: LessonFormFields,
) => UpdateLessonDTO = ({ timeFrom, timeTo, startDate, finishDate, ...restFormFields }) => ({
    ...restFormFields,
    timeFrom: parseDayjsTimeIntoHoursMinutesFormat(timeFrom),
    timeTo: parseDayjsTimeIntoHoursMinutesFormat(timeTo),
    startDate: parseDayjsDateIntoString(startDate, 'YYYY-MM-DD'),
    endDate: parseDayjsDateIntoString(finishDate, 'YYYY-MM-DD'),
});
