import React from 'react';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { useSetAtom } from 'jotai';
import { isLoggedUserDrawerMenuOpenAtom } from 'jotaiAtoms/isLoggedUserDrawerMenuOpenAtom';
import { AppButton } from 'components/atoms/CommonAppComponents';
import { useOtherAbilities } from 'abilities';
import { institutionTexts } from 'consts/text';
import { StyledPlusIcon } from 'components/atoms/StyledIcons';
import { GlobalStyle, StyledSearchInput } from './DropdownRender.styled';

export type DropDownRenderProps = {
    menu: React.ReactElement;
    onSearchInputChange?: (searchValue: string) => void;
};

export const DropdownRender = ({ menu, onSearchInputChange }: DropDownRenderProps) => {
    const setIsLoggedUserDrawerMenuOpen = useSetAtom(isLoggedUserDrawerMenuOpenAtom);
    const navigate = useNavigate();
    const {
        otherAbilities: { checkIfCanAddInstitution },
    } = useOtherAbilities();

    const canAddInstitution = checkIfCanAddInstitution();

    const navigateToInstitutionAdd = () => {
        navigate(appPaths.app['add-institution']);
        setIsLoggedUserDrawerMenuOpen(false);
    };

    const handleSearchInputChange = (event: React.FormEvent<HTMLInputElement>) =>
        onSearchInputChange?.(event.currentTarget.value);

    return (
        <div className="institutionDropdown">
            <GlobalStyle />
            {onSearchInputChange && <StyledSearchInput onInput={handleSearchInputChange} />}
            {menu}
            {canAddInstitution && (
                <AppButton
                    marginTop={8}
                    fullWidth
                    type="primary"
                    icon={<StyledPlusIcon />}
                    onClick={navigateToInstitutionAdd}
                >
                    {institutionTexts.addInstitution}
                </AppButton>
            )}
        </div>
    );
};
