import qs from 'qs';
import { RcFile } from 'antd/es/upload';
import {
    UserId,
    Child,
    OwnerCreateData,
    GusInstitutionDetails,
    InstitutionCreateData,
    InstitutionDto,
    GroupDTO,
    PaginationResponse,
    GroupId,
    PaginationParams,
    AddEmployeeUserDTO,
    EmployeeUser,
    UpdateEmployeeUserDTO,
    ChildId,
    AddChildDTO,
    AddGroupDTO,
    GetChildrenFilters,
    UpdateChildDTO,
    ChildGroupStatus,
    JournalsFilters,
    JournalDTO,
    JournalId,
    ParentId,
    AddParentDTO,
    PsychologicalHelpDTO,
    PsychologicalHelpId,
    UpdateGroupDTO,
    AddGuardianDTO,
    GuardianId,
    GroupConsultationId,
    GroupConsultationDTO,
    InspectionId,
    InspectionDTO,
    JournalChildDTO,
    GroupTopicDTO,
    GroupTopicId,
    AddUpdatePsychologicalHelpDTO,
    FileDirectory,
    FileMetaDTO,
    AddUpdateInspectionDTO,
    TripDTO,
    TripId,
    RoleId,
    Role,
    AddUpdateTripDTO,
    ChildProfilePhotoDTO,
    RoleTemplate,
    RolePostDTO,
    GroupDailyJournalAttendanceDTO,
    GroupDayAttendanceDTO,
    UpdateGroupDayAttendanceDTO,
    ImportantEventDTO,
    ImportantEventId,
    AddUpdateImportantEventDTO,
    AddUpdateDailyScheduleDTO,
    DailyScheduleDTO,
    AddUpdateGroupConsultationDTO,
    WeeklyScheduleDTO,
    AddUpdateWeeklyScheduleDTO,
    ParentTeacherConferenceDTO,
    ParentTeacherConferenceId,
    AddUpdateParentTeacherConferenceDTO,
    CoreCurriculumDTO,
    AddUpdateGroupTopicDTO,
    GroupTeacherDTO,
    SpecialConsultationId,
    AddUpdateSpecialConsultationDTO,
    SpecialConsultationDTO,
    AddSpecialJournalDTO,
    UpdateSpecialJournalDTO,
    AddUpdateQualificationDTO,
    QualificationDTO,
    QualificationId,
    QualificationBasisDto,
    LessonsFilters,
    LessonDTO,
    ChildDetailed,
    LessonId,
    CreateLessonDTO,
    UpdateLessonDTO,
    SpecialTopicDTO,
    AddUpdateSpecialTopicDTO,
    SpecialTopicId,
    EmployeeId,
    DietDTO,
    InstitutionId,
    DietId,
    AddUpdateDietDTO,
    DailySpecialJournalAttendanceDTO,
    MonthlySpecialJournalAttendanceDTO,
    DayAttendanceType,
    MonthlyGroupJournalAttendanceDTO,
    MenuDTO,
    MenuId,
    AddUpdateMenuDTO,
    UpdatePrincipalDTO,
    PrincipalDTO,
    UpdateJournalChildDatesDTO,
    MailThreadDTO,
    MailThreadId,
    UpdateOwnerDTO,
    RemoteLessonSimpleDTO,
    RemoteLessonAddUpdateDTO,
    RemoteLessonId,
    InvoiceDTO,
    UpdateInvoiceDTO,
    SettlementMealsDTO,
    UpdateSettlementMealsDto,
    SettlementStaysDTO,
    AddDiscountDTO,
    DiscountId,
    DiscountDTO,
    EditDiscountDTO,
    ExtraLessonsOptionsDTO,
    DateRange,
    BillDTO,
    RolesSchemaDTO,
    CalendarEventDetailedDtos,
    WithId,
    CreateCalendarEventDto,
    EmployeeDTO,
    GetHistoryOfChangesFilters,
    HistoryChangeDTO,
    AbsenceDTO,
    AbsenceId,
    UpdateAbsenceDTO,
    TimetableFilters,
    TimetableDTO,
    CreateAbsenceDTO,
    EventId,
    TimetableWorkingTimeDayDTO,
    WorkingTimeCreateUpdateDTO,
    AddMailThreadDTO,
    MailThreadReceiversDTO,
    AddMessageDTO,
    MessagesPaginationParams,
    MailPageMessageDTO,
    MessageWithoutReceiversDTO,
    MailThreadsFilters,
    SettlementOverallDTO,
    UpdateSettlementOverallDTO,
    RemoteLessonRecipientsDTO,
    RemoteLessonDTO,
    EvidenceWorkingTimeDTO,
    CourseId,
    WorkingTimeDTO,
    SpecialAttendanceDTO,
    CalendarEventDto,
    InstitutionBasicDto,
    ChildParent,
    UpdateInstitutionDto,
    AdditionalLessonDTO,
    AdditionalLessonJournalDTO,
    EditAdditionalLessonDTO,
    ChildCostAllocation,
    CostAllocationId,
    AddChildCostAllocation,
    EditChildCostAllocation,
    OwnerDTO,
    SettlementChildDTO,
    MonthlySettlementSimpleDTO,
    ChildPayment,
    ChildSettlementId,
    PaymentId,
    AbsencesDTO,
    ChildSimpleDTO,
    StaffSubscriptionDTO,
    SubscriptionChildrenNumbersDto,
    PaymentDto,
    RemoteLessonsFilters,
    SettlementChildrenSortBy,
    SorterParams,
    CreateTransactionDto,
} from 'types';
import { DailyLessonAttendanceDTO } from 'types/specialAttendance/specialAttendance';
import { REQUEST_FORM_FILE } from 'consts/api/requestFormFile';
import {
    EducationalGuideTextbookId,
    EducationalGuideTextbooks,
    EducationalGuideTextbookUnits,
    EducationalGuideTopic,
    EducationalGuideTopicId,
} from 'types/educationalGuide/educationalGuide';
import { StaffDTO } from 'types/staff/staff';
import omit from 'lodash/omit';
import StaffAxiosInstance from 'services/HTTPService/staff/StaffAxiosInstance';
import { GUS, OWNER } from './endpoints/singleInstances';
import { ACTIVATION_EMAIL } from './endpoints/auth';
import {
    ARCHIVE_CHILD,
    CHILDREN,
    CHILD_DETAILS,
    CHILD_GUARDIAN,
    CHILD_GUARDIAN_BASE,
    CHILD_PARENT,
    CHILD_PARENT_BASE,
    CHILD_PROFILE_PHOTO,
    RESTORE_CHILD,
    CHILD_LIMIT,
} from './endpoints/children';
import { ARCHIVE_GROUP, GROUP, GROUPS, GROUPS_EMPLOYEES } from './endpoints/group';
import { EMPLOYEES } from './endpoints/employees';
import {
    JOURNALS,
    JOURNAL,
    ARCHIVE_JOURNAL,
    JOURNAL_CHILDREN,
    JOURNAL_TEACHERS,
    JOURNAL_CHILD_DATES,
    JOURNALS_GROUPS,
    JOURNALS_EMPLOYEES,
} from './endpoints/journals';
import { PSYCHOLOGICAL_HELPS, PSYCHOLOGICAL_HELP } from './endpoints/psychologicalHelps';
import { TRIP, TRIPS } from './endpoints/trips';
import { IMPORTANT_EVENT, IMPORTANT_EVENTS } from './endpoints/importantEvents';
import { GROUP_CONSULTATIONS, GROUP_CONSULTATION } from './endpoints/groupConsultations';
import { INSPECTIONS, INSPECTION } from './endpoints/inspections';
import { ROLES, ROLE_DETAILS, ROLE_SCHEMA, ROLE_TEMPLATES } from './endpoints/roles';
import { ADD_TEMPORARY_FILE, FILE, GET_FILES_IN_ZIP } from './endpoints/files';
import { GROUP_TOPICS, GROUP_TOPIC } from './endpoints/groupTopics';
import { GROUP_DAILY_ATTENDANCE, GROUP_MONTHLY_ATTENDANCE } from './endpoints/groupAttendance';
import { DAILY_SCHEDULE, WEEKLY_SCHEDULE } from './endpoints/schedules';
import {
    PARENT_TEACHER_CONFERENCE,
    PARENT_TEACHER_CONFERENCES,
} from './endpoints/parentTeacherConferences';
import { CORE_CURRICULUMS } from './endpoints/coreCurriculums';
import {
    LESSON,
    LESSONS,
    LESSONS_EMPLOYEES,
    LESSONS_JOURNALS,
    UNASSIGNED_CHILDREN,
} from './endpoints/lessons';
import { SPECIAL_CONSULTATION, SPECIAL_CONSULTATIONS } from './endpoints/specialConsultations';
import { QUALIFICATION, QUALIFICATION_CHILD, QUALIFICATIONS } from './endpoints/qualifications';
import { JOURNAL_REPORT } from './endpoints/reports';
import { SPECIAL_TOPIC, SPECIAL_TOPICS } from './endpoints/specialTopics';
import {
    SPECIAL_ATTENDANCE,
    SPECIAL_DAILY_ATTENDANCE,
    SPECIAL_MONTHLY_ATTENDANCE,
} from './endpoints/specialAttendance';
import {
    EDUCATIONAL_GUIDE_TEXTBOOK_UNITS,
    EDUCATIONAL_GUIDE_TEXTBOOKS,
    EDUCATIONAL_GUIDE_TOPIC,
} from './endpoints/educationalGuide';
import { DIET, DIETS } from './endpoints/diets';
import {
    MAIL_THREAD,
    MAIL_THREAD_MESSAGES,
    MAIL_THREAD_RECEIVERS,
    MAIL_THREADS,
    REPLY_MAIL_THREAD,
    SUBSCRIBE_TO_NOTIFICATIONS,
    UNREAD_MAIL_THREADS,
} from './endpoints/mailThreads';
import {
    MENU,
    MENUS,
    MENUS_GROUPS,
    MENUS_REPORTS_CATERING,
    MENUS_REPORTS_GIVEAWAY,
} from './endpoints/menus';
import { REMOTE_LESSON, REMOTE_LESSONS, REMOTE_LESSON_RECEIVERS } from './endpoints/remoteLessons';
import {
    PRINCIPAL,
    INSTITUTIONS,
    INSTITUTION,
    INSTITUTIONS_EMPLOYEES,
} from './endpoints/institutions';
import { SETTLEMENT_MEALS, SETTLEMENT_OVERALL, SETTLEMENT_STAYS } from './endpoints/settings';
import { DISCOUNT, DISCOUNTS } from './endpoints/discounts';
import {
    COST_ALLOCATION,
    COST_ALLOCATIONS,
    EXTRA_LESSON_JOURNALS,
    EXTRA_LESSON,
    EXTRA_LESSONS_SETTLEMENT,
    EXTRA_LESSONS,
    STAFF,
    MONTHLY_SETTLEMENT,
    CHILD_SETTLEMENT,
    CHILD_SETTLEMENT_TRANSACTIONS,
    CHILD_SETTLEMENT_TRANSACTION,
} from './endpoints/settlements';
import {
    CALENDAR_EVENT,
    CALENDAR_EVENTS,
    CALENDAR_EVENTS_DAILY,
    CALENDAR_EVENTS_MONTHLY,
} from './endpoints/calendar';
import {
    TIMETABLE_EVIDENCE_WORKING_TIME,
    TIMETABLE_EVIDENCE_WORKING_TIME_REPORT,
    TIMETABLE_WEEKLY,
    TIMETABLE_WORKING_TIME_DAILY,
    WORKING_TIME,
} from './endpoints/timetable';
import { ABSENCE, ABSENCES } from './endpoints/absences';
import { HISTORY_OF_CHANGES } from './endpoints/historyOfChanges';
import { INVOICE } from './endpoints/invoices';
import { ACCOUNT_PACKAGE, PAYMENT_REDIRECT, PAYMENTS } from './endpoints/subscriptions';

class StaffHTTPService {
    private axios = new StaffAxiosInstance();

    public roles = {
        getRoles: () => this.axios.get<Role[]>(ROLES),
        postRoles: (roleDetails: RolePostDTO) => this.axios.post<Role>(ROLES, roleDetails),
        getRoleDetails: (roleId: RoleId) =>
            this.axios.get<Role>(ROLE_DETAILS(roleId), {
                params: { roleId },
            }),
        updateRole: ({ id, ...updatedRoleData }: Role) =>
            this.axios.put<Role>(ROLE_DETAILS(id), {
                params: { roleId: id },
                ...updatedRoleData,
            }),
        deleteRole: (roleId: RoleId) =>
            this.axios.delete(ROLE_DETAILS(roleId), {
                params: { roleId },
            }),
        getRolesTemplates: () => this.axios.get<RoleTemplate[]>(ROLE_TEMPLATES),
        getRolesSchema: () => this.axios.get<RolesSchemaDTO>(ROLE_SCHEMA),
    };

    public gus = {
        getInstitutionDetails: (regon: string) =>
            this.axios.get<GusInstitutionDetails>(GUS, {
                params: { regon },
            }),
    };

    public owner = {
        postOwner: (body: OwnerCreateData) => this.axios.post<OwnerCreateData>(OWNER, body),
        updateOwner: (updateOwnerDTO: UpdateOwnerDTO) =>
            this.axios.put<OwnerDTO>(OWNER, updateOwnerDTO),
    };

    public authentication = {
        sendActivationEmail: (userId: UserId) => this.axios.post(ACTIVATION_EMAIL(userId)),
    };

    public employees = {
        getEmployees: (search?: string) =>
            this.axios.get<EmployeeUser[]>(EMPLOYEES(), {
                params: { search },
            }),
        getEmployee: (employeeId: EmployeeId) =>
            this.axios.get<EmployeeUser>(EMPLOYEES(employeeId), {
                params: { employeeId },
            }),
        createEmployee: (employeeUserDTO: AddEmployeeUserDTO) =>
            this.axios.post<EmployeeUser>(EMPLOYEES(), employeeUserDTO),
        updateEmployee: ({
            employeeId,
            employeeUserDTO,
        }: {
            employeeId: EmployeeId;
            employeeUserDTO: UpdateEmployeeUserDTO;
        }) => this.axios.put<EmployeeUser>(EMPLOYEES(employeeId), employeeUserDTO),
        deleteEmployee: (employeeId: EmployeeId) =>
            this.axios.delete(EMPLOYEES(employeeId), {
                params: { employeeId },
            }),
    };

    public institutions = {
        getInstitutionDetails: (institutionId: InstitutionId) =>
            this.axios.get<InstitutionBasicDto>(INSTITUTION(institutionId)),
        updateInstitutionDetails: ({
            institutionId,
            updateInstitutionDTO,
        }: {
            institutionId: InstitutionId;
            updateInstitutionDTO: UpdateInstitutionDto;
        }) => this.axios.put<InstitutionBasicDto>(INSTITUTION(institutionId), updateInstitutionDTO),
        postInstitutions: (body: InstitutionCreateData) =>
            this.axios.post<InstitutionDto>(INSTITUTIONS, body),
        getPrincipal: (institutionId: InstitutionId) =>
            this.axios.get<PrincipalDTO>(PRINCIPAL(institutionId)),
        updatePrincipal: ({
            institutionId,
            updatePrincipalDTO,
        }: {
            institutionId: InstitutionId;
            updatePrincipalDTO: UpdatePrincipalDTO;
        }) => this.axios.patch<PrincipalDTO>(PRINCIPAL(institutionId), updatePrincipalDTO),
        getPayments: () => this.axios.get<BillDTO[]>(PAYMENTS),
        getPaymentRedirect: (billId: PaymentId) =>
            this.axios.post<PaymentDto>(PAYMENT_REDIRECT, null, { params: { billId } }),
        getInstitutionsEmployees: (institutionId: InstitutionId) =>
            this.axios.get<EmployeeDTO[]>(INSTITUTIONS_EMPLOYEES(institutionId)),
    };

    public subscription = {
        getAccountPackage: () => this.axios.get<StaffSubscriptionDTO>(ACCOUNT_PACKAGE),
    };

    public invoiceData = {
        getInvoiceData: () => this.axios.get<InvoiceDTO>(INVOICE),
        updateInvoiceData: (invoiceData: UpdateInvoiceDTO) =>
            this.axios.put<InvoiceDTO>(INVOICE, invoiceData),
    };

    public historyOfChanges = {
        getHistoryOfChanges: ({
            institutionId,
            filters,
            paginationParams,
        }: {
            institutionId: InstitutionId;
            filters: GetHistoryOfChangesFilters;
            paginationParams?: PaginationParams;
        }) =>
            this.axios.get<PaginationResponse<HistoryChangeDTO>>(HISTORY_OF_CHANGES, {
                params: { institutionId, ...filters, ...paginationParams },
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            }),
    };

    public settings = {
        getSettlementMeals: () => this.axios.get<SettlementMealsDTO>(SETTLEMENT_MEALS),
        getSettlementStays: () => this.axios.get<SettlementStaysDTO>(SETTLEMENT_STAYS),
        updateSettlementMeals: ({
            settlementMealsDTO,
        }: {
            settlementMealsDTO: UpdateSettlementMealsDto;
        }) => this.axios.put<SettlementMealsDTO>(SETTLEMENT_MEALS, settlementMealsDTO),
        updateSettlementStays: ({
            settlementStaysDTO,
        }: {
            settlementStaysDTO: SettlementStaysDTO;
        }) => this.axios.put<SettlementStaysDTO>(SETTLEMENT_STAYS, settlementStaysDTO),
        getSettlementOverall: () => this.axios.get<SettlementOverallDTO>(SETTLEMENT_OVERALL),
        updateSettlementOverall: ({
            settlementOverallDTO,
        }: {
            settlementOverallDTO: UpdateSettlementOverallDTO;
        }) => this.axios.put<SettlementOverallDTO>(SETTLEMENT_OVERALL, settlementOverallDTO),
    };

    public groups = {
        getGroups: (filters?: { search?: string; status?: ChildGroupStatus }) =>
            this.axios.get<GroupDTO[]>(GROUPS, {
                params: filters,
            }),
        getGroup: (groupId: GroupId) => this.axios.get<GroupDTO>(GROUP(groupId)),
        archiveGroup: (groupId: GroupId) => this.axios.post(ARCHIVE_GROUP(groupId)),
        createGroup: (addGroupDTO: AddGroupDTO) => this.axios.post<GroupDTO>(GROUPS, addGroupDTO),
        updateGroup: ({
            groupId,
            updateGroupDTO,
        }: {
            groupId: GroupId;
            updateGroupDTO: UpdateGroupDTO;
        }) => this.axios.put<GroupDTO>(GROUP(groupId), updateGroupDTO),
        getGroupsEmployees: () => this.axios.get<EmployeeDTO[]>(GROUPS_EMPLOYEES),
    };

    public children = {
        getChildren: ({
            search,
            filters,
            paginationParams,
        }: {
            search?: string;
            filters?: GetChildrenFilters;
            paginationParams?: PaginationParams;
        }) =>
            this.axios.get<PaginationResponse<Child>>(CHILDREN, {
                params: { search, ...filters, ...paginationParams },
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            }),
        getChild: (childId: ChildId) =>
            this.axios.get<ChildDetailed>(CHILD_DETAILS(childId), {
                params: { childId },
            }),
        getChildLimit: () => this.axios.get<SubscriptionChildrenNumbersDto>(CHILD_LIMIT),
        archiveChild: (childId: ChildId) => this.axios.post(ARCHIVE_CHILD(childId)),
        restoreChild: (childId: ChildId) => this.axios.post(RESTORE_CHILD(childId)),
        postChild: (addChildDTO: AddChildDTO) =>
            this.axios.post<{ child: Child }>(CHILDREN, addChildDTO),
        updateChild: ({
            childId,
            updateChildDTO,
        }: {
            childId: ChildId;
            updateChildDTO: UpdateChildDTO;
        }) => this.axios.put<ChildDetailed>(CHILD_DETAILS(childId), updateChildDTO),
        profilePhoto: {
            updateProfilePhoto: ({
                childId,
                childProfilePhotoDTO,
            }: {
                childId: ChildId;
                childProfilePhotoDTO: ChildProfilePhotoDTO;
            }) =>
                this.axios.put<ChildProfilePhotoDTO>(
                    CHILD_PROFILE_PHOTO(childId),
                    childProfilePhotoDTO,
                ),
            deleteProfilePhoto: ({ childId }: { childId: ChildId }) =>
                this.axios.delete(CHILD_PROFILE_PHOTO(childId)),
        },
        parent: {
            addParent: ({
                childId,
                childParent,
            }: {
                childId: ChildId;
                childParent: AddParentDTO;
            }) => this.axios.post<Child>(CHILD_PARENT_BASE(childId), childParent),
            updateParent: ({
                childId,
                parentId,
                childParent,
            }: {
                childId: ChildId;
                parentId: ParentId;
                childParent: AddParentDTO;
            }) => this.axios.put<ChildParent>(CHILD_PARENT(childId, parentId), childParent),
            deleteParent: ({ childId, parentId }: { childId: ChildId; parentId: ParentId }) =>
                this.axios.delete(CHILD_PARENT(childId, parentId)),
        },
        guardian: {
            addGuardian: ({
                childId,
                childGuardian,
            }: {
                childId: ChildId;
                childGuardian: AddGuardianDTO;
            }) => this.axios.post<Child>(CHILD_GUARDIAN_BASE(childId), childGuardian),
            updateGuardian: ({
                childId,
                guardianId,
                childGuardian,
            }: {
                childId: ChildId;
                guardianId: GuardianId;
                childGuardian: AddGuardianDTO;
            }) => this.axios.put<Child>(CHILD_GUARDIAN(childId, guardianId), childGuardian),
            deleteGuardian: ({
                childId,
                guardianId,
            }: {
                childId: ChildId;
                guardianId: GuardianId;
            }) => this.axios.delete<Child>(CHILD_GUARDIAN(childId, guardianId)),
        },
    };

    public journals = {
        getJournals: (filters?: JournalsFilters) =>
            this.axios.get<JournalDTO[]>(JOURNALS, { params: filters }),
        getJournal: (journalId: JournalId) => this.axios.get<JournalDTO>(JOURNAL(journalId)),
        archiveJournal: (journalId: JournalId) => this.axios.post(ARCHIVE_JOURNAL(journalId)),
        children: {
            getJournalChildren: (journalId: JournalId, datesParams?: DateRange) =>
                this.axios.get<JournalChildDTO[]>(JOURNAL_CHILDREN(journalId), {
                    params: datesParams,
                }),
            patchJournalChildDates: ({
                journalId,
                childId,
                values,
            }: {
                journalId: JournalId;
                childId: ChildId;
                values: UpdateJournalChildDatesDTO;
            }) => this.axios.patch(JOURNAL_CHILD_DATES(journalId, childId), values),
        },
        getJournalTeachers: (journalId: JournalId, datesParams?: DateRange) =>
            this.axios.get<GroupTeacherDTO[]>(JOURNAL_TEACHERS(journalId), { params: datesParams }),
        addSpecialJournal: (specialJournalDTO: AddSpecialJournalDTO) =>
            this.axios.post<JournalDTO>(JOURNALS, specialJournalDTO),
        editSpecialJournal: ({
            journalId,
            specialJournalDTO,
        }: {
            journalId: JournalId;
            specialJournalDTO: UpdateSpecialJournalDTO;
        }) => this.axios.put<JournalDTO[]>(JOURNAL(journalId), specialJournalDTO),
        getJournalsGroups: () => this.axios.get<GroupDTO[]>(JOURNALS_GROUPS),
        getJournalsEmployees: () => this.axios.get<EmployeeDTO[]>(JOURNALS_EMPLOYEES),
    };

    public psychologicalHelps = {
        getPsychologicalHelps: (journalId: JournalId) =>
            this.axios.get<PsychologicalHelpDTO[]>(PSYCHOLOGICAL_HELPS(journalId)),
        getPsychologicalHelp: (journalId: JournalId, psychologicalHelpId: PsychologicalHelpId) =>
            this.axios.get<PsychologicalHelpDTO>(
                PSYCHOLOGICAL_HELP(journalId, psychologicalHelpId),
            ),
        addPsychologicalHelp: ({
            journalId,
            addPsychologicalHelpDTO,
        }: {
            journalId: JournalId;
            addPsychologicalHelpDTO: AddUpdatePsychologicalHelpDTO;
        }) =>
            this.axios.post<PsychologicalHelpDTO>(
                PSYCHOLOGICAL_HELPS(journalId),
                addPsychologicalHelpDTO,
            ),
        updatePsychologicalHelp: ({
            journalId,
            psychologicalHelpId,
            updatePsychologicalHelpDTO,
        }: {
            journalId: JournalId;
            psychologicalHelpId: PsychologicalHelpId;
            updatePsychologicalHelpDTO: AddUpdatePsychologicalHelpDTO;
        }) =>
            this.axios.put<PsychologicalHelpDTO>(
                PSYCHOLOGICAL_HELP(journalId, psychologicalHelpId),
                updatePsychologicalHelpDTO,
            ),
        removePsychologicalHelp: ({
            journalId,
            psychologicalHelpId,
        }: {
            journalId: JournalId;
            psychologicalHelpId: PsychologicalHelpId;
        }) => this.axios.delete(PSYCHOLOGICAL_HELP(journalId, psychologicalHelpId)),
    };

    public groupConsultations = {
        getGroupConsultations: (journalId: JournalId) =>
            this.axios.get<GroupConsultationDTO[]>(GROUP_CONSULTATIONS(journalId)),
        getGroupConsultation: (journalId: JournalId, consultationId: GroupConsultationId) =>
            this.axios.get<GroupConsultationDTO>(GROUP_CONSULTATION(journalId, consultationId)),
        addGroupConsultation: ({
            journalId,
            addGroupConsultationDTO,
        }: {
            journalId: JournalId;
            addGroupConsultationDTO: AddUpdateGroupConsultationDTO;
        }) =>
            this.axios.post<GroupConsultationDTO>(
                GROUP_CONSULTATIONS(journalId),
                addGroupConsultationDTO,
            ),
        updateGroupConsultation: ({
            journalId,
            consultationId,
            updateGroupConsultationDTO,
        }: {
            journalId: JournalId;
            consultationId: GroupConsultationId;
            updateGroupConsultationDTO: AddUpdateGroupConsultationDTO;
        }) =>
            this.axios.put<GroupConsultationDTO>(
                GROUP_CONSULTATION(journalId, consultationId),
                updateGroupConsultationDTO,
            ),
        removeGroupConsultation: ({
            journalId,
            consultationId,
        }: {
            journalId: JournalId;
            consultationId: GroupConsultationId;
        }) => this.axios.delete(GROUP_CONSULTATION(journalId, consultationId)),
    };

    public specialConsultations = {
        getSpecialConsultations: (journalId: JournalId) =>
            this.axios.get<SpecialConsultationDTO[]>(SPECIAL_CONSULTATIONS(journalId)),
        getSpecialConsultation: (journalId: JournalId, consultationId: SpecialConsultationId) =>
            this.axios.get<SpecialConsultationDTO>(SPECIAL_CONSULTATION(journalId, consultationId)),
        addSpecialConsultation: ({
            journalId,
            addSpecialConsultationDTO,
        }: {
            journalId: JournalId;
            addSpecialConsultationDTO: AddUpdateSpecialConsultationDTO;
        }) =>
            this.axios.post<SpecialConsultationDTO>(
                SPECIAL_CONSULTATIONS(journalId),
                addSpecialConsultationDTO,
            ),
        updateSpecialConsultation: ({
            journalId,
            consultationId,
            updateSpecialConsultationDTO,
        }: {
            journalId: JournalId;
            consultationId: SpecialConsultationId;
            updateSpecialConsultationDTO: AddUpdateSpecialConsultationDTO;
        }) =>
            this.axios.put<SpecialConsultationDTO>(
                SPECIAL_CONSULTATION(journalId, consultationId),
                updateSpecialConsultationDTO,
            ),
        removeSpecialConsultation: ({
            journalId,
            consultationId,
        }: {
            journalId: JournalId;
            consultationId: SpecialConsultationId;
        }) => this.axios.delete(SPECIAL_CONSULTATION(journalId, consultationId)),
    };

    public inspections = {
        getInspections: (journalId: JournalId) =>
            this.axios.get<InspectionDTO[]>(INSPECTIONS(journalId)),
        getInspection: (journalId: JournalId, inspectionId: InspectionId) =>
            this.axios.get<InspectionDTO>(INSPECTION(journalId, inspectionId)),
        addInspection: ({
            journalId,
            addInspectionDTO,
        }: {
            journalId: JournalId;
            addInspectionDTO: AddUpdateInspectionDTO;
        }) => this.axios.post<InspectionDTO>(INSPECTIONS(journalId), addInspectionDTO),
        updateInspection: ({
            journalId,
            inspectionId,
            updateInspectionDTO,
        }: {
            journalId: JournalId;
            inspectionId: InspectionId;
            updateInspectionDTO: AddUpdateInspectionDTO;
        }) =>
            this.axios.put<InspectionDTO>(INSPECTION(journalId, inspectionId), updateInspectionDTO),
        removeInspection: ({
            journalId,
            inspectionId,
        }: {
            journalId: JournalId;
            inspectionId: InspectionId;
        }) => this.axios.delete(INSPECTION(journalId, inspectionId)),
    };

    public groupTopics = {
        getGroupTopics: (journalId: JournalId, date: string) =>
            this.axios.get<GroupTopicDTO[]>(GROUP_TOPICS(journalId), { params: { date } }),
        getGroupTopic: (journalId: JournalId, groupTopicId: GroupTopicId) =>
            this.axios.get<GroupTopicDTO>(GROUP_TOPIC(journalId, groupTopicId)),
        removeGroupTopic: ({
            journalId,
            groupTopicId,
        }: {
            journalId: JournalId;
            groupTopicId: GroupTopicId;
        }) => this.axios.delete(GROUP_TOPIC(journalId, groupTopicId)),
        addGroupTopic: ({
            journalId,
            addGroupTopicDTO,
        }: {
            journalId: JournalId;
            addGroupTopicDTO: AddUpdateGroupTopicDTO;
        }) => this.axios.post<GroupTopicDTO>(GROUP_TOPICS(journalId), addGroupTopicDTO),
        editGroupTopic: ({
            journalId,
            groupTopicId,
            updateGroupTopicDTO,
        }: {
            journalId: JournalId;
            groupTopicId: GroupTopicId;
            updateGroupTopicDTO: AddUpdateGroupTopicDTO;
        }) =>
            this.axios.put<GroupTopicDTO>(
                GROUP_TOPIC(journalId, groupTopicId),
                updateGroupTopicDTO,
            ),
    };

    public specialTopics = {
        getSpecialTopics: (journalId: JournalId, date: string) =>
            this.axios.get<SpecialTopicDTO[]>(SPECIAL_TOPICS(journalId), { params: { date } }),
        getSpecialTopic: (journalId: JournalId, specialTopicId: SpecialTopicId) =>
            this.axios.get<SpecialTopicDTO>(SPECIAL_TOPIC(journalId, specialTopicId)),
        removeSpecialTopic: ({
            journalId,
            specialTopicId,
        }: {
            journalId: JournalId;
            specialTopicId: SpecialTopicId;
        }) => this.axios.delete(SPECIAL_TOPIC(journalId, specialTopicId)),
        addSpecialTopic: ({
            journalId,
            addSpecialTopicDTO,
        }: {
            journalId: JournalId;
            addSpecialTopicDTO: AddUpdateSpecialTopicDTO;
        }) => this.axios.post<SpecialTopicDTO>(SPECIAL_TOPICS(journalId), addSpecialTopicDTO),
        editSpecialTopic: ({
            journalId,
            specialTopicId,
            updateSpecialTopicDTO,
        }: {
            journalId: JournalId;
            specialTopicId: SpecialTopicId;
            updateSpecialTopicDTO: AddUpdateSpecialTopicDTO;
        }) =>
            this.axios.put<SpecialTopicDTO>(
                SPECIAL_TOPIC(journalId, specialTopicId),
                updateSpecialTopicDTO,
            ),
    };

    public files = {
        addFile: ({
            directory,
            file,
        }: {
            directory: FileDirectory;
            file: string | Blob | RcFile;
        }) => {
            const formData = new FormData();
            formData.append(REQUEST_FORM_FILE, file);
            return this.axios.post<FileMetaDTO>(ADD_TEMPORARY_FILE, formData, {
                params: { directory },
            });
        },
        getFile: (fileUri: string) =>
            this.axios.get<string>(FILE, {
                responseType: 'arraybuffer',
                params: { fileUri },
            }),
        getFilesInZip: (files: string[]) =>
            this.axios.post<string>(
                GET_FILES_IN_ZIP,
                { fileUris: files },
                {
                    responseType: 'arraybuffer',
                },
            ),
    };

    public trips = {
        getTrips: (journalId: JournalId) => this.axios.get<TripDTO[]>(TRIPS(journalId)),
        getTrip: (journalId: JournalId, tripId: TripId) =>
            this.axios.get<TripDTO>(TRIP(journalId, tripId)),
        addTrip: ({
            addTripDTO,
            journalId,
        }: {
            addTripDTO: AddUpdateTripDTO;
            journalId: JournalId;
        }) => this.axios.post<TripDTO>(TRIPS(journalId), addTripDTO),
        updateTrip: ({
            journalId,
            tripId,
            updateTripDTO,
        }: {
            journalId: JournalId;
            tripId: TripId;
            updateTripDTO: AddUpdateTripDTO;
        }) => this.axios.put<TripDTO>(TRIP(journalId, tripId), updateTripDTO),
        removeTrip: ({ journalId, tripId }: { journalId: JournalId; tripId: TripId }) =>
            this.axios.delete(TRIP(journalId, tripId)),
    };

    public coreCurriculums = {
        getCoreCurriculums: () => this.axios.get<CoreCurriculumDTO[]>(CORE_CURRICULUMS),
    };

    public parentTeacherConferences = {
        getParentTeacherConferences: (journalId: JournalId) =>
            this.axios.get<ParentTeacherConferenceDTO[]>(PARENT_TEACHER_CONFERENCES(journalId)),
        getParentTeacherConference: (
            journalId: JournalId,
            parentTeacherConferenceId: ParentTeacherConferenceId,
        ) =>
            this.axios.get<ParentTeacherConferenceDTO>(
                PARENT_TEACHER_CONFERENCE(journalId, parentTeacherConferenceId),
            ),
        addParentTeacherConference: ({
            addParentTeacherConferenceDTO,
            journalId,
        }: {
            addParentTeacherConferenceDTO: AddUpdateParentTeacherConferenceDTO;
            journalId: JournalId;
        }) =>
            this.axios.post<ParentTeacherConferenceDTO>(
                PARENT_TEACHER_CONFERENCES(journalId),
                addParentTeacherConferenceDTO,
            ),
        updateParentTeacherConference: ({
            journalId,
            parentTeacherConferenceId,
            updateParentTeacherConferenceDTO,
        }: {
            journalId: JournalId;
            parentTeacherConferenceId: ParentTeacherConferenceId;
            updateParentTeacherConferenceDTO: AddUpdateParentTeacherConferenceDTO;
        }) =>
            this.axios.put<ParentTeacherConferenceDTO>(
                PARENT_TEACHER_CONFERENCE(journalId, parentTeacherConferenceId),
                updateParentTeacherConferenceDTO,
            ),
        removeParentTeacherConference: ({
            journalId,
            parentTeacherConferenceId,
        }: {
            journalId: JournalId;
            parentTeacherConferenceId: ParentTeacherConferenceId;
        }) => this.axios.delete(PARENT_TEACHER_CONFERENCE(journalId, parentTeacherConferenceId)),
    };

    public importantEvents = {
        getImportantEvents: (journalId: JournalId) =>
            this.axios.get<ImportantEventDTO[]>(IMPORTANT_EVENTS(journalId)),
        getImportantEvent: (journalId: JournalId, importantEventId: ImportantEventId) =>
            this.axios.get<ImportantEventDTO>(IMPORTANT_EVENT(journalId, importantEventId)),
        addImportantEvent: ({
            addImportantEventDTO,
            journalId,
        }: {
            addImportantEventDTO: AddUpdateImportantEventDTO;
            journalId: JournalId;
        }) => this.axios.post<ImportantEventDTO>(IMPORTANT_EVENTS(journalId), addImportantEventDTO),
        updateImportantEvent: ({
            journalId,
            importantEventId,
            updateImportantEventDTO,
        }: {
            journalId: JournalId;
            importantEventId: ImportantEventId;
            updateImportantEventDTO: AddUpdateImportantEventDTO;
        }) =>
            this.axios.put<ImportantEventDTO>(
                IMPORTANT_EVENT(journalId, importantEventId),
                updateImportantEventDTO,
            ),
        removeImportantEvent: ({
            journalId,
            importantEventId,
        }: {
            journalId: JournalId;
            importantEventId: ImportantEventId;
        }) => this.axios.delete(IMPORTANT_EVENT(journalId, importantEventId)),
    };

    public schedules = {
        dailySchedule: {
            getDailySchedule: (journalId: JournalId) =>
                this.axios.get<DailyScheduleDTO>(DAILY_SCHEDULE(journalId)),
            updateDailySchedule: ({
                journalId,
                addUpdateDailyScheduleDTO,
            }: {
                journalId: JournalId;
                addUpdateDailyScheduleDTO: AddUpdateDailyScheduleDTO;
            }) =>
                this.axios.patch<DailyScheduleDTO>(
                    DAILY_SCHEDULE(journalId),
                    addUpdateDailyScheduleDTO,
                ),
        },
        weeklySchedule: {
            getWeeklySchedule: (journalId: JournalId) =>
                this.axios.get<WeeklyScheduleDTO>(WEEKLY_SCHEDULE(journalId)),
            updateWeeklySchedule: ({
                journalId,
                addUpdateWeeklyScheduleDTO,
            }: {
                journalId: JournalId;
                addUpdateWeeklyScheduleDTO: AddUpdateWeeklyScheduleDTO;
            }) =>
                this.axios.put<WeeklyScheduleDTO>(
                    WEEKLY_SCHEDULE(journalId),
                    addUpdateWeeklyScheduleDTO,
                ),
        },
    };

    public groupAttendance = {
        getGroupDailyAttendance: (journalId: JournalId, date: string) =>
            this.axios.get<GroupDailyJournalAttendanceDTO>(GROUP_DAILY_ATTENDANCE(journalId), {
                params: { date },
            }),
        updateGroupDailyAttendance: ({
            journalId,
            childId,
            date,
            updateDayAttendanceDTO,
        }: {
            journalId: JournalId;
            childId: ChildId;
            date: string;
            updateDayAttendanceDTO: UpdateGroupDayAttendanceDTO;
        }) =>
            this.axios.put<GroupDayAttendanceDTO>(
                GROUP_DAILY_ATTENDANCE(journalId),
                updateDayAttendanceDTO,
                {
                    params: {
                        date,
                        childId,
                    },
                },
            ),
        deleteGroupDailyAttendance: ({
            journalId,
            childId,
            date,
        }: {
            journalId: JournalId;
            childId: ChildId;
            date: string;
        }) =>
            this.axios.delete(GROUP_DAILY_ATTENDANCE(journalId), {
                params: {
                    date,
                    childId,
                },
            }),
        getGroupMonthlyAttendance: (journalId: JournalId, year: number, month: number) =>
            this.axios.get<MonthlyGroupJournalAttendanceDTO>(GROUP_MONTHLY_ATTENDANCE(journalId), {
                params: { year, month },
            }),
    };

    public specialAttendance = {
        getAttendanceByLessonId: (journalId: JournalId, lessonId: LessonId) =>
            this.axios.get<DailyLessonAttendanceDTO>(SPECIAL_ATTENDANCE(journalId, lessonId)),
        getSpecialMonthlyAttendance: (journalId: JournalId, year: number, month: number) =>
            this.axios.get<MonthlySpecialJournalAttendanceDTO>(
                SPECIAL_MONTHLY_ATTENDANCE(journalId),
                {
                    params: { year, month },
                },
            ),
        getSpecialDailyAttendance: (journalId: JournalId, date: string) =>
            this.axios.get<DailySpecialJournalAttendanceDTO>(SPECIAL_DAILY_ATTENDANCE(journalId), {
                params: { date },
            }),
        updateSpecialDailyAttendance: ({
            journalId,
            childId,
            lessonId,
            type,
        }: {
            journalId: JournalId;
            lessonId: LessonId;
            childId: ChildId;
            type: DayAttendanceType;
        }) =>
            this.axios.put<SpecialAttendanceDTO>(SPECIAL_DAILY_ATTENDANCE(journalId), {
                lessonId,
                childId,
                type,
            }),
    };

    public employeeSchedule = {
        getLessons: (filters?: LessonsFilters) =>
            this.axios.get<LessonDTO[]>(LESSONS, { params: filters }),
        getEmployeeSchedule: (filters: TimetableFilters) =>
            this.axios.get<TimetableDTO>(TIMETABLE_WEEKLY, { params: filters }),
        getLesson: (id: LessonId) => this.axios.get<LessonDTO>(LESSON(id)),
        addLesson: (addLessonDTO: CreateLessonDTO) =>
            this.axios.post<LessonDTO>(LESSONS, addLessonDTO),
        editLesson: ({
            id,
            editLessonDTO,
            affectNext,
        }: {
            id: LessonId;
            editLessonDTO: UpdateLessonDTO;
            affectNext: boolean;
        }) => this.axios.put<LessonDTO>(LESSON(id), editLessonDTO, { params: { affectNext } }),
        removeLesson: ({ id, affectNext }: { id: LessonId; affectNext: boolean }) =>
            this.axios.delete(LESSON(id), { params: { affectNext } }),
        getLessonsEmployees: () => this.axios.get<EmployeeUser[]>(LESSONS_EMPLOYEES),
        getLessonsJournals: (filters?: JournalsFilters) =>
            this.axios.get<JournalDTO[]>(LESSONS_JOURNALS, { params: filters }),
        addAbsence: (addAbsenceDTO: CreateAbsenceDTO) =>
            this.axios.post<AbsencesDTO>(ABSENCES, addAbsenceDTO),
        editAbsence: ({
            id,
            editAbsenceDTO,
        }: {
            id: AbsenceId;
            editAbsenceDTO: UpdateAbsenceDTO;
        }) => this.axios.put<AbsencesDTO>(ABSENCE(id), editAbsenceDTO),
        getAbsence: (id: AbsenceId) => this.axios.get<AbsenceDTO>(ABSENCE(id)),
        removeAbsence: (id: AbsenceId) => this.axios.delete(ABSENCE(id)),
        getWorkingTime: (date: string, search: string) =>
            this.axios.get<TimetableWorkingTimeDayDTO>(TIMETABLE_WORKING_TIME_DAILY, {
                params: { date, search },
            }),
        updateWorkingTime: ({
            teacherId,
            date,
            updateCreateWorkingTimeDTO,
        }: {
            teacherId: EmployeeId;
            date: string;
            updateCreateWorkingTimeDTO: WorkingTimeCreateUpdateDTO;
        }) =>
            this.axios.put<WorkingTimeDTO>(WORKING_TIME, {
                ...updateCreateWorkingTimeDTO,
                date,
                teacherId,
            }),
        getEvidenceWorkingTime: (selectedMonth: number, selectedYear: number, search: string) =>
            this.axios.get<EvidenceWorkingTimeDTO>(TIMETABLE_EVIDENCE_WORKING_TIME, {
                params: { month: selectedMonth, year: selectedYear, search },
            }),
        getUnassignedChildren: (lessonId: LessonId) =>
            this.axios.get<ChildSimpleDTO[]>(UNASSIGNED_CHILDREN(lessonId)),
    };

    public qualifications = {
        getQualifications: (specialJournalId: JournalId) =>
            this.axios.get<QualificationDTO[]>(QUALIFICATIONS(specialJournalId)),
        getQualification: (specialJournalId: JournalId, qualificationId: QualificationId) =>
            this.axios.get<QualificationBasisDto>(QUALIFICATION(specialJournalId, qualificationId)),
        getChildForQualification: (specialJournalId: JournalId, childId: ChildId) =>
            this.axios.get<QualificationDTO>(QUALIFICATION_CHILD(specialJournalId, childId)),
        addUpdateQualification: ({
            specialJournalId,
            addUpdateQualificationDTO,
        }: {
            specialJournalId: JournalId;
            addUpdateQualificationDTO: AddUpdateQualificationDTO;
        }) =>
            this.axios.put<QualificationDTO>(
                QUALIFICATIONS(specialJournalId),
                addUpdateQualificationDTO,
            ),
    };

    public reports = {
        getJournalReport: (journalId: JournalId) =>
            this.axios.post(JOURNAL_REPORT, null, {
                params: { journalId },
                responseType: 'arraybuffer',
            }),
        getEvidenceWorkingTimeReport: (selectedMonth: number, selectedYear: number) =>
            this.axios.post(TIMETABLE_EVIDENCE_WORKING_TIME_REPORT, null, {
                params: { month: selectedMonth, year: selectedYear },
                responseType: 'arraybuffer',
            }),
    };

    public educationalGuide = {
        getEducationalGuideTextbooks: () =>
            this.axios.get<EducationalGuideTextbooks>(EDUCATIONAL_GUIDE_TEXTBOOKS),
        getEducationalGuideTextbookUnits: (textbookId: EducationalGuideTextbookId) =>
            this.axios.get<EducationalGuideTextbookUnits>(
                EDUCATIONAL_GUIDE_TEXTBOOK_UNITS(textbookId),
            ),
        getEducationalGuideTopic: (topicId: EducationalGuideTopicId) =>
            this.axios.get<EducationalGuideTopic>(EDUCATIONAL_GUIDE_TOPIC(topicId)),
    };

    public diets = {
        getDiets: () => this.axios.get<DietDTO[]>(DIETS),

        getDiet: (dietId: DietId) => this.axios.get<DietDTO>(DIET(dietId)),

        addDiet: (addDietData: AddUpdateDietDTO) =>
            this.axios.post<AddUpdateDietDTO>(DIETS, addDietData),

        updateDiet: ({
            dietId,
            updateDietDTO,
        }: {
            dietId: DietId;
            updateDietDTO: AddUpdateDietDTO;
        }) => this.axios.put<DietDTO>(DIET(dietId), updateDietDTO),

        archiveDiet: (dietId: DietId) => this.axios.delete(DIET(dietId)),
    };

    public menus = {
        getMenus: ({ month, year }: { month: number; year: number }) =>
            this.axios.get<MenuDTO[]>(MENUS, { params: { month, year } }),
        getMenu: (menuId: MenuId) => this.axios.get<MenuDTO>(MENU(menuId)),
        addMenu: ({ addMenuData }: { addMenuData: AddUpdateMenuDTO }) =>
            this.axios.post<AddUpdateMenuDTO>(MENUS, { ...addMenuData }),
        updateMenu: ({
            menuId,
            updateMenuDTO,
        }: {
            menuId: MenuId;
            updateMenuDTO: AddUpdateMenuDTO;
        }) => this.axios.put<MenuDTO>(MENU(menuId), { ...updateMenuDTO }),
        removeMenu: (menuId: MenuId) => this.axios.delete(MENU(menuId)),
        getCateringReport: ({ year, month }: { year: number; month: number }) =>
            this.axios.post(MENUS_REPORTS_CATERING, null, {
                params: { year, month },
                responseType: 'arraybuffer',
            }),
        getGiveAwayReport: ({
            year,
            month,
            groupsIds,
        }: {
            year: number;
            month: number;
            groupsIds: GroupId[];
        }) =>
            this.axios.post(
                MENUS_REPORTS_GIVEAWAY,
                { groupIds: groupsIds },
                {
                    params: { year, month },
                    responseType: 'arraybuffer',
                },
            ),
        getMenusGroups: () => this.axios.get<GroupDTO[]>(MENUS_GROUPS),
    };

    public remoteLessons = {
        getRemoteLessons: (remoteLessonsFilters: RemoteLessonsFilters) =>
            this.axios.get<RemoteLessonSimpleDTO[]>(REMOTE_LESSONS, {
                params: remoteLessonsFilters,
            }),
        getRemoteLesson: (remoteLessonId: RemoteLessonId) =>
            this.axios.get<RemoteLessonDTO>(REMOTE_LESSON(remoteLessonId)),
        addRemoteLesson: (remoteLesson: RemoteLessonAddUpdateDTO) =>
            this.axios.post<RemoteLessonAddUpdateDTO>(REMOTE_LESSONS, remoteLesson),
        updateRemoteLesson: ({
            remoteLessonDTO,
            remoteLessonId,
        }: {
            remoteLessonDTO: RemoteLessonAddUpdateDTO;
            remoteLessonId: RemoteLessonId;
        }) =>
            this.axios.put<RemoteLessonAddUpdateDTO>(
                REMOTE_LESSON(remoteLessonId),
                remoteLessonDTO,
            ),
        removeRemoteLesson: (remoteLessonId: RemoteLessonId) =>
            this.axios.delete(REMOTE_LESSON(remoteLessonId)),
        getRemoteLessonRecipients: () =>
            this.axios.get<RemoteLessonRecipientsDTO>(REMOTE_LESSON_RECEIVERS),
    };

    public discounts = {
        getDiscounts: (childId: ChildId) =>
            this.axios.get<DiscountDTO[]>(DISCOUNTS, { params: { childId } }),
        addDiscount: (addDiscountData: AddDiscountDTO) =>
            this.axios.post(DISCOUNTS, addDiscountData),
        editDiscount: ({
            discountId,
            childId,
            editDiscountData,
        }: {
            discountId: DiscountId;
            childId: ChildId;
            editDiscountData: EditDiscountDTO;
        }) => this.axios.put(DISCOUNT(discountId), editDiscountData, { params: { childId } }),
        getDiscount: (discountId: DiscountId, childId: ChildId) =>
            this.axios.get<DiscountDTO>(DISCOUNT(discountId), { params: { childId } }),
        removeDiscount: ({ discountId, childId }: { discountId: DiscountId; childId: ChildId }) =>
            this.axios.delete(DISCOUNT(discountId), { params: { childId } }),
    };

    public settlements = {
        getCostAllocation: ({ costAllocationId }: { costAllocationId: CostAllocationId }) =>
            this.axios.get<ChildCostAllocation>(COST_ALLOCATION(costAllocationId)),
        getChildCostAllocations: ({ settlementId }: { settlementId: ChildSettlementId }) =>
            this.axios.get<ChildCostAllocation[]>(COST_ALLOCATIONS, {
                params: { monthlySettlementId: settlementId },
            }),
        addCostAllocation: ({
            childId,
            costAllocation,
        }: {
            // TODO: to change childId -> monthlySettlementId after backend changes
            childId: ChildId;
            costAllocation: AddChildCostAllocation;
        }) =>
            this.axios.post<ChildCostAllocation>(COST_ALLOCATIONS, { ...costAllocation, childId }),
        editCostAllocation: ({ costAllocation }: { costAllocation: EditChildCostAllocation }) =>
            this.axios.put<ChildCostAllocation>(
                COST_ALLOCATION(costAllocation.id),
                omit(costAllocation, 'id'),
            ),
        removeCostAllocation: ({ costAllocationId }: { costAllocationId: CostAllocationId }) =>
            this.axios.delete(COST_ALLOCATION(costAllocationId)),
        getExtraLessonsByChild: (childId: ChildId) =>
            this.axios.get<ExtraLessonsOptionsDTO>(EXTRA_LESSONS, { params: { childId } }),
        getExtraLessons: ({ paginationParams }: { paginationParams?: PaginationParams }) =>
            this.axios.get<PaginationResponse<AdditionalLessonDTO>>(EXTRA_LESSONS_SETTLEMENT, {
                params: { ...paginationParams },
            }),
        getJournalsForExtraLessons: (courseId: CourseId) =>
            this.axios.get<AdditionalLessonJournalDTO[]>(EXTRA_LESSON_JOURNALS(courseId)),
        editExtraLessons: ({
            id,
            editAdditionalLessonDTO,
        }: {
            id: CourseId;
            editAdditionalLessonDTO: EditAdditionalLessonDTO;
        }) => this.axios.put<ExtraLessonsOptionsDTO>(EXTRA_LESSON(id), editAdditionalLessonDTO),
        getStaff: ({
            search,
            month,
            year,
            paginationParams,
        }: {
            search?: string;
            month?: number;
            year?: number;
            paginationParams?: PaginationParams;
        }) =>
            this.axios.get<PaginationResponse<StaffDTO>>(STAFF, {
                params: { search, month, year, ...paginationParams },
            }),
        getMonthlySettlements: ({
            search,
            month,
            year,
            paginationParams,
            sorterParams,
        }: {
            search?: string;
            month?: number;
            year?: number;
            paginationParams?: PaginationParams;
            sorterParams?: SorterParams<SettlementChildrenSortBy>;
        }) =>
            this.axios.get<PaginationResponse<SettlementChildDTO>>(MONTHLY_SETTLEMENT, {
                params: { search, month, year, ...paginationParams, ...sorterParams },
            }),
        getChildSettlement: ({
            childId,
            month,
            year,
        }: {
            childId: ChildId;
            month?: number;
            year?: number;
        }) =>
            this.axios.get<MonthlySettlementSimpleDTO>(CHILD_SETTLEMENT, {
                params: { month, year, childId },
            }),
        getChildTransactions: ({
            childId,
            paginationParams,
        }: {
            childId: ChildId;
            paginationParams?: PaginationParams;
        }) =>
            this.axios.get<PaginationResponse<ChildPayment>>(CHILD_SETTLEMENT_TRANSACTIONS, {
                params: { childId, ...paginationParams },
            }),
        getChildTransaction: ({
            childId,
            transactionId,
        }: {
            childId: ChildId;
            transactionId: PaymentId;
        }) =>
            this.axios.get<ChildPayment>(CHILD_SETTLEMENT_TRANSACTION(transactionId), {
                params: { childId },
            }),
        addChildTransaction: ({
            childId,
            transactionAddData,
        }: {
            childId: ChildId;
            transactionAddData: CreateTransactionDto;
        }) =>
            this.axios.post<ChildPayment>(CHILD_SETTLEMENT_TRANSACTIONS, transactionAddData, {
                params: { childId },
            }),
        editChildTransaction: ({
            transactionId,
            childId,
            transactionEditData,
        }: {
            transactionId: PaymentId;
            childId: ChildId;
            transactionEditData: CreateTransactionDto;
        }) =>
            this.axios.put<ChildPayment>(CHILD_SETTLEMENT_TRANSACTIONS, transactionEditData, {
                params: { id: transactionId, childId },
            }),
        removeChildTransaction: ({ transactionId }: { transactionId: PaymentId }) =>
            this.axios.delete(CHILD_SETTLEMENT_TRANSACTIONS, {
                params: { id: transactionId },
            }),
        recalculateSettlement: ({
            childId,
            settlementId,
        }: {
            childId: ChildId;
            settlementId: ChildSettlementId;
        }) =>
            this.axios.patch<MonthlySettlementSimpleDTO>(MONTHLY_SETTLEMENT, null, {
                params: { childId, monthlySettlementId: settlementId },
            }),
    };

    public mailThreads = {
        getMailThreads: ({
            filters,
            paginationParams,
        }: {
            filters?: MailThreadsFilters;
            paginationParams?: PaginationParams;
        }) =>
            this.axios.get<PaginationResponse<MailThreadDTO>>(MAIL_THREADS, {
                params: { ...filters, ...paginationParams },
            }),
        getMailThread: (mailThreadId: MailThreadId) =>
            this.axios.get<MailThreadDTO>(MAIL_THREAD(mailThreadId)),
        getReplyMailThread: (mailThreadId: MailThreadId) =>
            this.axios.get<MailThreadDTO | null>(REPLY_MAIL_THREAD(mailThreadId)),
        addMailThread: (addMailThreadDTO: AddMailThreadDTO) =>
            this.axios.post<MailThreadDTO>(MAIL_THREADS, addMailThreadDTO),
        removeMailThread: (mailThreadId: MailThreadId) =>
            this.axios.delete(MAIL_THREAD(mailThreadId)),
        getMailThreadReceivers: () => this.axios.get<MailThreadReceiversDTO>(MAIL_THREAD_RECEIVERS),
        notifications: {
            subscribeToUnreadMessageNotifications: (firebaseMessagingToken: string) =>
                this.axios.post(SUBSCRIBE_TO_NOTIFICATIONS, null, {
                    params: { firebaseMessagingToken },
                }),
            getUnreadThreads: () => this.axios.get<number>(UNREAD_MAIL_THREADS),
        },
        messages: {
            addMessage: ({
                mailThreadId,
                addMessageDTO,
            }: {
                mailThreadId: MailThreadId;
                addMessageDTO: AddMessageDTO;
            }) =>
                this.axios.post<MessageWithoutReceiversDTO>(
                    MAIL_THREAD_MESSAGES(mailThreadId),
                    addMessageDTO,
                ),
            getMailThreadMessages: ({
                mailThreadId,
                paginationParams,
            }: {
                mailThreadId: MailThreadId;
                paginationParams?: MessagesPaginationParams;
            }) =>
                this.axios.get<MailPageMessageDTO>(MAIL_THREAD_MESSAGES(mailThreadId), {
                    params: { threadId: mailThreadId, ...paginationParams },
                }),
        },
    };

    public calendar = {
        getMonthlyEvents: ({ month, year }: { month: number; year: number }) =>
            this.axios.get<CalendarEventDetailedDtos>(CALENDAR_EVENTS_MONTHLY, {
                params: { month, year },
            }),
        getDailyEvents: ({ date }: { date: string }) =>
            this.axios.get<string[]>(CALENDAR_EVENTS_DAILY, {
                params: { date },
            }),
        createEvent: (addEventData: CreateCalendarEventDto) =>
            this.axios.post<CalendarEventDto>(CALENDAR_EVENTS, addEventData),
        editEvent: (editEventData: WithId<CreateCalendarEventDto>) =>
            this.axios.put<CalendarEventDto>(
                CALENDAR_EVENT(editEventData.id),
                omit(editEventData, 'id'),
            ),
        removeEvent: (eventId: EventId) => this.axios.delete(CALENDAR_EVENT(eventId)),
    };
}

export default new StaffHTTPService();
