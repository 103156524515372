import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts, remoteLessonsTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { RemoteLessonsFilters } from 'types';

export type RemoteLessonsPageNetworkManageProps = {
    filtersValues: RemoteLessonsFilters;
    onJournalArchiveRequestFinish: () => void;
};

export const useRemoteLessonsPageNetworkManage = ({
    filtersValues,
    onJournalArchiveRequestFinish,
}: RemoteLessonsPageNetworkManageProps) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: remoteLessonsData,
        isInitialLoading: isRemoteLessonsDataInitialLoading,
        isRefetching: isRemoteLessonsDataRefetching,
        isError: isRemoteLessonsDataError,
    } = useAppQuery(
        'REMOTE_LESSONS',
        [filtersValues],
        () => StaffHTTPService.remoteLessons.getRemoteLessons(filtersValues),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const { mutate: removeRemoteLesson, isLoading: isRemoveRemoteLessonLoading } = useAppMutation(
        StaffHTTPService.remoteLessons.removeRemoteLesson,
        {
            key: ['REMOVE_REMOTE_LESSON'],
            onSuccess: async (_, variables) => {
                onJournalArchiveRequestFinish();
                // variables is RemoteLessonId
                await queryClient.invalidateQueries([institutionId, 'REMOTE_LESSON', variables]);
                displaySuccess(remoteLessonsTexts.notifications.removeSuccess);
            },
            onError: () => {
                onJournalArchiveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['REMOTE_LESSONS']],
        },
    );

    return {
        remoteLessonsData,
        isRemoteLessonsDataInitialLoading,
        isRemoteLessonsDataError,
        isRemoteLessonsDataRefetching,
        removeRemoteLesson,
        isRemoveRemoteLessonLoading,
    };
};
