import styled, { createGlobalStyle, css } from 'styled-components';
import { Select } from 'antd';
import { getTextStyle } from 'utils/getTextStyle';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { inputAndPickerBorderStyle } from 'consts/styles/inputAndPickerBorderStyle';
import type { AppSelectProps } from './AppSelect';

export const StyledSelect = styled(Select).withConfig({
    shouldForwardProp: getShouldForwardProp(['isFitContentWidth', 'variant']),
})<Omit<AppSelectProps, 'icon'>>`
    ${({ theme, isFitContentWidth, variant }) => css`
        height: 44px;
        display: flex;
        flex: ${isFitContentWidth ? 'initial' : 1};
        overflow: hidden;

        &.ant-select-in-form-item {
            height: 44px;
        }

        .ant-select-selector {
            flex: 1;
        }

        &.ant-select div.ant-select-selector {
            ${inputAndPickerBorderStyle}
            ${variant === 'borderless' && 'border:none !important;'}

            .ant-select-selection-item {
                ${getTextStyle('BodyMedium')}
            }
        }

        &.ant-select.ant-select-single div.ant-select-selector {
            padding: 8px 16px;
        }

        &.ant-select.ant-select-disabled {
            &.ant-select-show-search {
                .ant-select-selector {
                    cursor: not-allowed;
                }
            }

            div.ant-select-selector {
                background-color: ${theme.colors.grayscale.gray2};
                border: 1px solid ${theme.colors.grayscale.gray4};
            }

            .ant-select-arrow svg {
                color: ${theme.colors.grayscale.gray7};
            }

            span {
                color: ${theme.colors.grayscale.gray7};
            }
        }

        .ant-select-arrow {
            transition: rotate 0.3s;
            rotate: 0deg;
            right: 12px;

            &:not(.ant-select-arrow-loading) {
                svg {
                    color: ${theme.colors.grayscale.gray10};
                }
            }
        }

        .ant-select-clear {
            width: 20px;
            height: 20px;
            margin-top: -10px;
            right: 14px;
        }

        &.ant-select-open {
            &.ant-select div.ant-select-selector {
                .ant-select-selection-item {
                    color: ${theme.colors.primary.primary10};
                }
            }
            .ant-select-arrow:not(.ant-select-arrow-loading) {
                rotate: 180deg;
            }
        }

        & .ant-select-selection-overflow-item-rest {
            background-color: rgba(0, 0, 0, 0.06);
            border-radius: 2px;
        }
    `}
`;

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
      .ant-select-dropdown {
          padding: 12px;
          ${getTextStyle('BodyMedium')};
          border: 1px solid ${theme.colors.primary.primary5};
          .ant-select-item-option {
              padding: 12px 8px;
              border-bottom: 1px solid ${theme.colors.primary.primary3};

              &.ant-select-item-option-disabled {
                  color: ${theme.colors.grayscale.gray9};
                  background-color: ${theme.colors.grayscale.gray4};

                  &.ant-select-item-option-selected {
                      font-weight: 600;
                      background-color: ${theme.colors.grayscale.gray5};
                  }
              }
          }

          .ant-select-item-empty {
              .ant-empty-image > svg > g {
                  g {
                      stroke: ${theme.colors.grayscale.gray10};
                  }

                  ellipse {
                      fill: ${theme.colors.grayscale.gray10};
                  }
              }

              .ant-empty-description {
                  color: ${theme.colors.grayscale.gray10};
              }
          }
      }
  `}
`;

export const OuterContainer = styled.div.withConfig({
    shouldForwardProp: getShouldForwardProp(['isFitContentWidth']),
})<{ isFitContentWidth?: boolean }>`
    ${({ isFitContentWidth }) => css`
        width: ${isFitContentWidth ? 'auto' : '100%'};
        display: flex;
        flex-direction: column;
        gap: ${({ theme }) => theme.sizes.marginSmall}px;
    `}
`;

export const InnerContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        width: 100%;
        + svg {
            fill: ${theme.colors.grayscale.gray10};
        }
        &:has(div.ant-select-open) {
            + svg {
                fill: ${theme.colors.primary.primary10};
            }
        }
    `}
`;
