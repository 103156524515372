import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts, journalTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';

export const specialDailyAttendanceUpdateErrorParser: (error: unknown) => string = (error) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.LESSON_ACCESS_FORBIDDEN) {
        return journalTexts.journalDetails.specialJournal.attendance
            .lessonAccessForbiddenErrorMessage;
    }

    return commonTexts.errorMessages.actionExecution;
};
