import { AppTableProps } from 'components/organisms';
import { GetRemoteLessonsFilterKey, RemoteLessonSimpleDTO } from 'types';
import { commonTexts, remoteLessonsTexts } from 'consts/text';
import { remoteLessonSimpleDTOFields } from 'consts/remoteLessons/remoteLessonSimpleDTOFields';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { ContractValidDisplay, FirstAndLastNameDisplay } from 'components/molecules';
import { PageFilter } from 'hooks/usePageFilters/usePageFilters';

export const columns: AppTableProps<RemoteLessonSimpleDTO>['columns'] = [
    {
        title: remoteLessonsTexts.lessonListColumns.title,
        dataIndex: remoteLessonSimpleDTOFields.name,
        key: remoteLessonSimpleDTOFields.name,
    },
    {
        title: remoteLessonsTexts.lessonListColumns.date,
        dataIndex: remoteLessonSimpleDTOFields.date,
        key: remoteLessonSimpleDTOFields.date,
        render: (date: RemoteLessonSimpleDTO['date']) =>
            parseStringIntoFormattedStringDate(date, 'YYYY-MM-DD', 'DD/MM/YYYY'),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: remoteLessonsTexts.lessonListColumns.creator,
        key: remoteLessonSimpleDTOFields.creatorFirstName,
        render: (_, remoteLessonDTO) => (
            <FirstAndLastNameDisplay
                firstName={remoteLessonDTO.creatorFirstName}
                lastName={remoteLessonDTO.creatorLastName}
            />
        ),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: remoteLessonsTexts.lessonListColumns.homeworkDateTo,
        dataIndex: remoteLessonSimpleDTOFields.homeworkDeadline,
        key: remoteLessonSimpleDTOFields.homeworkDeadline,
        render: (homeworkDeadline: RemoteLessonSimpleDTO['homeworkDeadline']) =>
            homeworkDeadline
                ? parseStringIntoFormattedStringDate(homeworkDeadline, 'YYYY-MM-DD', 'DD/MM/YYYY')
                : EMPTY_VALUE_LABEL,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: remoteLessonsTexts.lessonListColumns.reads,
        dataIndex: remoteLessonSimpleDTOFields.readBy,
        key: remoteLessonSimpleDTOFields.readBy,
        render: (_, remoteLessonDTO) =>
            `${remoteLessonDTO.readBy}/${remoteLessonDTO.totalAmountOfRecipients}`,
        align: 'center',
    },
    {
        title: commonTexts.dataLabels.attachments,
        dataIndex: remoteLessonSimpleDTOFields.attachmentAttached,
        key: remoteLessonSimpleDTOFields.attachmentAttached,
        render: (attachmentAttached: RemoteLessonSimpleDTO['attachmentAttached']) => (
            <ContractValidDisplay contractValid={attachmentAttached} dataTestId="attachments" />
        ),
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
];

export const filters: PageFilter<GetRemoteLessonsFilterKey>[] = [
    { name: 'startYear', type: 'SINGLE_VALUE' },
    { name: 'endYear', type: 'SINGLE_VALUE' },
];
