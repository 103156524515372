import { ReactNode } from 'react';
import styled from 'styled-components';
import {
    AddButton,
    AppCardListBase,
    AppCardListBaseProps,
    ComponentBlockingAppLoader,
} from 'components/atoms';
import {
    AppTableAndCardListHeader,
    AppTableAndCardListHeaderProps,
    EmptyDisplay,
    EmptyDisplayProps,
    ErrorDisplay,
    ErrorDisplayProps,
    LoadingDisplay,
} from 'components/molecules';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

type AppCardListProps<ListItem> = AppCardListBaseProps<ListItem> &
    EmptyDisplayProps &
    Omit<AppTableAndCardListHeaderProps, 'mode'> & {
        isDataInitialLoading?: boolean;
        isDataRefetching?: boolean;
        isError?: boolean;
        isHiddenHeader?: boolean;
        filters?: ReactNode;
        errorStateConfig?: Pick<ErrorDisplayProps, 'title' | 'subTitle' | 'hideSubTitle' | 'icon'>;
    };

const FiltersContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.sizes.marginNormal}px;
`;

export const AppCardList = <ListItem,>({
    dataSource,
    title,
    addButtonLabel,
    emptyInfo,
    isDataInitialLoading,
    isDataRefetching,
    isError,
    isHiddenAddButton,
    isHiddenHeader,
    filters,
    errorStateConfig,
    onAddButtonClick,
    ...restListProps
}: AppCardListProps<ListItem>) => {
    const isMobile = useIsMobile();

    const isEmpty = !dataSource?.length;

    const header = isHiddenHeader ? undefined : (
        <AppTableAndCardListHeader
            mode="LIST"
            title={title}
            addButtonLabel={addButtonLabel}
            isHiddenAddButton={isHiddenAddButton}
            onAddButtonClick={onAddButtonClick}
        />
    );

    const renderState = () => {
        if (isError) {
            return <ErrorDisplay {...errorStateConfig} />;
        }
        if (isDataInitialLoading) {
            return <LoadingDisplay />;
        }
        if (isEmpty) {
            return <EmptyDisplay emptyInfo={emptyInfo} />;
        }
        return null;
    };

    return (
        <>
            {isMobile && !isHiddenAddButton && (
                <AddButton
                    fullWidth
                    addButtonLabel={addButtonLabel}
                    onAddButtonClick={onAddButtonClick}
                    marginBottom={12}
                />
            )}
            {filters && <FiltersContainer>{filters}</FiltersContainer>}
            <AppCardListBase
                dataSource={dataSource}
                header={!isMobile ? header : undefined}
                {...restListProps}
            >
                {renderState()}
            </AppCardListBase>
            {isDataRefetching && <ComponentBlockingAppLoader />}
        </>
    );
};
