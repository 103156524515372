import { SettlementChildrenDtoFieldWithSorting, SettlementChildrenSortBy } from 'types';

export const settlementChildrenDtoFieldIntoSettlementChildrenSortByMapper: Record<
    SettlementChildrenDtoFieldWithSorting,
    SettlementChildrenSortBy
> = {
    lastName: SettlementChildrenSortBy.NAME,
    mainGroup: SettlementChildrenSortBy.GROUP,
    settlementPeriodEnd: SettlementChildrenSortBy.SETTLEMENT_END,
};
