import {
    FiltersParams,
    JournalStatus,
    JournalType,
    JournalsFiltersWithNoFilterValues,
    NoFilterValueType,
} from 'types';
import { journalFilterFields } from 'consts/filters/journal/journalFilterFields';
import { NO_FILTER_VALUE } from 'consts/filters/common/defaultFilterValue';

export const parseFilterParamsIntoJournalsFiltersWithNoFilterValues: (
    filterParams: FiltersParams,
    searchFilterParam: string,
) => JournalsFiltersWithNoFilterValues = (filterParams, searchFilterParam) => {
    const fromYearNonUndefinedValue =
        filterParams[journalFilterFields.fromYear] === NO_FILTER_VALUE
            ? NO_FILTER_VALUE
            : Number(filterParams[journalFilterFields.fromYear]);
    const toYearNonUndefinedValue =
        filterParams[journalFilterFields.toYear] === NO_FILTER_VALUE
            ? NO_FILTER_VALUE
            : Number(filterParams[journalFilterFields.toYear]);

    return {
        type: filterParams[journalFilterFields.type]
            ? (filterParams[journalFilterFields.type] as JournalType)
            : undefined,
        status: filterParams[journalFilterFields.status]
            ? (filterParams[journalFilterFields.status] as JournalStatus | NoFilterValueType)
            : undefined,
        search: searchFilterParam,
        fromYear: filterParams[journalFilterFields.fromYear]
            ? fromYearNonUndefinedValue
            : undefined,
        toYear: filterParams[journalFilterFields.toYear] ? toYearNonUndefinedValue : undefined,
    };
};
