import { settlementChildrenDTOFields } from 'consts/settlementChildren/settlementChildrenDTOFields';

export enum SettlementChildrenSortBy {
    NAME = 'NAME',
    GROUP = 'GROUP',
    SETTLEMENT_END = 'SETTLEMENT_END',
}

export type SettlementChildrenDtoFieldWithSorting = Extract<
    keyof typeof settlementChildrenDTOFields,
    'mainGroup' | 'settlementPeriodEnd' | 'lastName'
>;
