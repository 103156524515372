import { useCallback, useEffect, useState } from 'react';
import { getToken, onMessage, Messaging } from 'firebase/messaging';
import { useAtomValue, useSetAtom } from 'jotai';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { unreadMailThreadsNumberAtom } from 'jotaiAtoms/unreadMailThreadsNumberAtom';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { useInvalidateMailThreads } from 'hooks/useInvalidateMailThreads/useInvalidateMailThreads';
import { isStaffUserType } from 'utils/isStaffUserType';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { handleNetworkError } from 'utils/handleNetworkError';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts } from 'consts/text';
import { getMessaging } from 'services/firebase/firebase';
import { userTypesWithoutMessagesModule } from 'consts/modules/userTypesWithoutMessagesModule';

const vapidKey: Record<ImportMeta['env']['VITE_APP_ENV'], string> = {
    DEV: 'BKanuE8ZxoWCudwaTAKbRqMfeh5kW1c-NbJTfpoJbMD2cwYObqL415vs7c2M69eswAQdmxJFR92BZ5ocPGHdvTk',
    UAT: 'BFKFcrnLlfdPmZJs-NIn4SVoiQSyMxA4MRUxd2BDJ47pIq-FzKtZ5BXcSC-UEerkQX8qz9iAnPzVoY8ROgP76PE',
    PROD: 'BFVmBERY9nvzykFrPsplt1JMngaIUTzb4D-bZKCjOoRsstnCWCKaqR19pNhLg_v7Ioer5AMpuZ0_7uhMC4lTTUc',
};

const UNREAD_MAIL_THREAD_NOTIFICATION_TITLE = 'Unread messages count';

const SUBSCRIBE_PUSH_MANAGER_ERROR_NAME = 'AbortError';

const GET_FCM_TOKEN_ATTEMPTS_NUMBER = 3;

export const useMessaging = () => {
    const [token, setToken] = useState<string>();
    const setUnreadMailThreadsNumberState = useSetAtom(unreadMailThreadsNumberAtom);
    const userDetails = useAtomValue(userDetailsAtom);
    const { displayWarning } = useSimpleNotification();

    const { invalidateMailThreadsData } = useInvalidateMailThreads();

    const userType = userDetails?.type;
    const isParentUserType = userType ? !isStaffUserType(userType) : undefined;
    const isMessagingDisabled = !userType || userTypesWithoutMessagesModule.includes(userType);

    const { mutateAsync: subscribeToNotifications } = useAppMutation(
        isParentUserType
            ? ParentHTTPService.mailThreads.notifications.subscribeToUnreadMessageNotifications
            : StaffHTTPService.mailThreads.notifications.subscribeToUnreadMessageNotifications,
        {
            key: ['SUBSCRIBE_TO_UNREAD_MESSAGE_NOTIFICATIONS'],
            onError: (error) => {
                handleNetworkError(error);
            },
        },
    );

    const requestForToken = useCallback(
        async (messagingSDK: Messaging) => {
            try {
                const permission = await Notification.requestPermission();

                switch (permission) {
                    case 'granted': {
                        const fcmToken = await getToken(messagingSDK, {
                            vapidKey: vapidKey[import.meta.env.VITE_APP_ENV],
                        });
                        return { fcmToken, isGrantedPermission: true };
                    }
                    case 'denied':
                    case 'default':
                    default: {
                        displayWarning(commonTexts.warningMessages.notActiveNotificationsWarning);
                        return { fcmToken: undefined, isGrantedPermission: false };
                    }
                }
            } catch (error) {
                const typedError = error as Error;
                if (typedError.name === SUBSCRIBE_PUSH_MANAGER_ERROR_NAME) {
                    console.warn(error);
                }
                console.error(error);
            }
            return { fcmToken: undefined, isGrantedPermission: false };
        },
        [displayWarning],
    );

    const receiveToken = useCallback(
        async (attempt = 1) => {
            const messaging = await getMessaging();
            const isMessagingSupported = !!messaging;
            if (!isMessagingSupported) {
                displayWarning(commonTexts.warningMessages.messagingIsNotSupportedByBrowserWarning);
                return;
            }
            const { fcmToken, isGrantedPermission } = await requestForToken(messaging);
            if (!isGrantedPermission) {
                return;
            }
            if (fcmToken) {
                setToken(fcmToken);
            } else if (attempt < GET_FCM_TOKEN_ATTEMPTS_NUMBER) {
                await receiveToken(attempt + 1);
            } else {
                displayWarning(commonTexts.warningMessages.activateNotificationsReloadPageWarning);
            }
        },
        [requestForToken, displayWarning],
    );

    useEffect(() => {
        if (isMessagingDisabled) {
            return;
        }
        receiveToken();
    }, [isMessagingDisabled, receiveToken]);

    const activateMessaging = useCallback(async () => {
        if (!token) {
            return;
        }
        await subscribeToNotifications(token);
    }, [token, subscribeToNotifications]);

    useEffect(() => {
        activateMessaging();
    }, [activateMessaging]);

    useEffect(() => {
        if (isMessagingDisabled) {
            return undefined;
        }

        let unsubscribe: (() => void) | null = null;
        let isMounted = true;

        const startListeningForMessages = async () => {
            const messaging = await getMessaging();
            if (!messaging || !isMounted) {
                return undefined;
            }
            unsubscribe = onMessage(messaging, async (payload) => {
                if (payload.notification?.title === UNREAD_MAIL_THREAD_NOTIFICATION_TITLE) {
                    setUnreadMailThreadsNumberState(Number(payload.notification.body));
                    await invalidateMailThreadsData();
                }
            });
            return undefined;
        };

        startListeningForMessages();

        return () => {
            isMounted = false;
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [invalidateMailThreadsData, isMessagingDisabled, setUnreadMailThreadsNumberState]);
};
