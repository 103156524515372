import { SettlementOverallDTO, SettlementOverallFormFields } from 'types';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';
import { DEFAULT_PAYMENT_DEADLINE } from 'consts/settlementOverall/defaultPaymenDeadline';

export const parseSettlementOverallDTOIntoSettlementOverallFormFields = (
    settlementOverallDTO: SettlementOverallDTO,
): SettlementOverallFormFields => ({
    workingDays: settlementOverallDTO.workingDays.workingDays,
    recipientName: settlementOverallDTO.recipientName || '',
    bankAccountNumber: settlementOverallDTO.bankAccountNumber
        ? parseBankAccountNumberIntoFormattedBankAccountNumber(
              settlementOverallDTO.bankAccountNumber,
          )
        : '',
    paymentDeadline: settlementOverallDTO.paymentDeadline || DEFAULT_PAYMENT_DEADLINE,
});
