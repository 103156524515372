import { SortOrder } from 'antd/es/table/interface';
import { SortDirection } from 'types';

export const sortOrderIntoApiSortDirectionMapper: Record<
    Extract<SortOrder, 'descend' | 'ascend'>,
    SortDirection
> = {
    ascend: SortDirection.ASC,
    descend: SortDirection.DESC,
};
