import { ChildPayment } from 'types';
import { AddBillFormFields } from 'types/forms/addBill';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';

export const parseTransactionDtoIntoTransactionFormFields: (
    transactionDto: ChildPayment,
) => AddBillFormFields = (transactionDto) => {
    const { amount, transactionType, paymentFormType, date, description } = transactionDto;

    return {
        amount,
        transactionType,
        paymentFormType,
        date: parseStringIntoDayjsDate(date),
        description: description || '',
    };
};
