import { ErrorStateIcon } from 'assets/icons';
import { commonTexts } from 'consts/text';
import { StateInfoBase, StateInfoBaseProps } from '../StateInfoBase/StateInfoBase';

export type ErrorDisplayProps = Pick<StateInfoBaseProps, 'title' | 'subTitle' | 'className'> &
    Partial<Pick<StateInfoBaseProps, 'icon'>> & {
        hideIcon?: boolean;
        hideSubTitle?: boolean;
    };

export const ErrorDisplay = ({
    title = commonTexts.errorMessages.errorStateDisplay.title,
    subTitle = commonTexts.errorMessages.errorStateDisplay.subTitle,
    className,
    hideIcon,
    hideSubTitle,
    icon = <ErrorStateIcon />,
}: ErrorDisplayProps) => (
    <StateInfoBase
        className={className}
        title={title}
        subTitle={hideSubTitle ? undefined : subTitle}
        icon={hideIcon ? undefined : icon}
    />
);
