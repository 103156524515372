import { useAtomValue } from 'jotai';
import { Badge } from 'antd';
import { AppFlex } from 'components/atoms/CommonAppComponents/AppFlex/AppFlex';
import { MessagesModuleIcon } from 'assets';
import { unreadMailThreadsNumberAtom } from 'jotaiAtoms/unreadMailThreadsNumberAtom';

export const MessagesModuleIconWithUnreadMessageIndicator = () => {
    const unreadMailThreadsNumberState = useAtomValue(unreadMailThreadsNumberAtom);

    return (
        <AppFlex>
            <Badge size="small" count={unreadMailThreadsNumberState}>
                <MessagesModuleIcon />
            </Badge>
        </AppFlex>
    );
};
