import { generatePath, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Details } from 'components/organisms';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { RemoteLessonId } from 'types';
import { appPaths } from 'consts/paths/paths';
import { commonTexts, journalTexts, remoteLessonsTexts } from 'consts/text';
import { AttachmentListItem, RemoteLessonPersonList } from 'components/molecules';
import { useRemoteLessonAbilities } from 'abilities';
import { AppLink, AppList } from 'components/atoms';
import { appLinkRegExp, spacesAndNewLinesRegExp } from 'consts/regexps';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { getFullName } from 'utils/getFullName';

export const RemoteLessonDetailsPage = () => {
    const { remoteLessonId } = useParams<{ remoteLessonId: RemoteLessonId }>();
    const theme = useTheme();
    const { remoteLessonAbilities } = useRemoteLessonAbilities();

    const editPagePath = generatePath(appPaths.app['remote-lessons'].edit.pickedRemoteLesson, {
        remoteLessonId,
    });

    const {
        data: remoteLessonData,
        isError: isRemoteLessonError,
        isFetching: isRemoteLessonDataFetching,
    } = useAppQuery(
        'REMOTE_LESSON',
        [remoteLessonId],
        () => StaffHTTPService.remoteLessons.getRemoteLesson(remoteLessonId as RemoteLessonId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { checkIfCanManageRemoteLessonsConfig } = remoteLessonAbilities;
    const canManageRemoteLessons = checkIfCanManageRemoteLessonsConfig();
    const canManageSelectedRemoteLesson = canManageRemoteLessons && remoteLessonData?.editable;

    const isVisibleAttachmentsList = !!remoteLessonData?.attachments?.length;

    const descriptionLinkContent = (word: string) => (
        <AppLink
            to={word}
            target="_blank"
            rel="noopener noreferrer"
            highlighted
            style={{
                overflowWrap: 'break-word',
            }}
        >
            {word}
        </AppLink>
    );

    const remoteLessonDescriptionDetailsContent =
        remoteLessonData &&
        remoteLessonData.description
            .split(spacesAndNewLinesRegExp)
            .map((word) => (appLinkRegExp.test(word) ? descriptionLinkContent(word) : word))
            .reduce(
                (prev: (string | JSX.Element)[], curr: string | JSX.Element, index: number) =>
                    index === 0 ? [curr] : [...prev, ' ', curr],
                [] as (string | JSX.Element)[],
            );

    return (
        <Details
            title={remoteLessonsTexts.detailsRemoteLesson}
            isLoading={isRemoteLessonDataFetching}
            isError={isRemoteLessonError}
            editPagePath={canManageSelectedRemoteLesson ? editPagePath : undefined}
        >
            <Details.Item title={remoteLessonsTexts.lessonFormLabels.name}>
                {remoteLessonData && remoteLessonData.name}
            </Details.Item>
            <Details.Item title={remoteLessonsTexts.lessonFormLabels.date}>
                {remoteLessonData &&
                    parseStringIntoFormattedStringDate(
                        remoteLessonData.date,
                        'YYYY-MM-DD',
                        'DD/MM/YYYY',
                    )}
            </Details.Item>
            <Details.Item title={remoteLessonsTexts.lessonFormLabels.creator}>
                {remoteLessonData &&
                    getFullName(
                        remoteLessonData.creatorFirstName,
                        remoteLessonData.creatorLastName,
                    )}
            </Details.Item>
            <Details.Item title={commonTexts.dataLabels.receivers}>
                {remoteLessonData && (
                    <RemoteLessonPersonList
                        personsData={[...remoteLessonData.children, ...remoteLessonData.employees]}
                    />
                )}
            </Details.Item>
            <Details.Item title={remoteLessonsTexts.lessonFormLabels.description}>
                {remoteLessonDescriptionDetailsContent}
            </Details.Item>
            {remoteLessonData && remoteLessonData.homework && (
                <Details.Item title={remoteLessonsTexts.lessonFormLabels.homeworkDescription}>
                    {remoteLessonData.homework}
                </Details.Item>
            )}
            {remoteLessonData && remoteLessonData.homeworkDeadline && (
                <Details.Item title={remoteLessonsTexts.lessonFormLabels.homeworkDateTo}>
                    {remoteLessonData &&
                        parseStringIntoFormattedStringDate(
                            remoteLessonData.homeworkDeadline,
                            'YYYY-MM-DD',
                            'DD/MM/YYYY',
                        )}
                </Details.Item>
            )}
            {isVisibleAttachmentsList && (
                <Details.Item
                    title={journalTexts.journalDetails.groupJournal.psychologicalHelp.documents}
                >
                    <AppList
                        dataSource={remoteLessonData.attachments || []}
                        marginTop={theme.sizes.marginNormal}
                        renderItem={(fileData) => (
                            <AttachmentListItem key={fileData.fileUri} fileData={fileData} />
                        )}
                    />
                </Details.Item>
            )}
        </Details>
    );
};
