import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging as getFirebaseMessaging, isSupported } from 'firebase/messaging';
import { FIREBASE_CONFIG } from './firebase.config';

const config = FIREBASE_CONFIG[import.meta.env.VITE_APP_ENV];

const app = initializeApp(config);
const auth = getAuth(app);

const getMessaging = async () => ((await isSupported()) ? getFirebaseMessaging(app) : null);

export { auth, app, getMessaging };
