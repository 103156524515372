import { DetailsColumn, DetailsFrameBase, DetailsRow, CopyContentText } from 'components/atoms';
import { parentTexts } from 'consts/text';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

type PaymentTransferDetailsProps = {
    title: string;
    recipientName: string | null;
    bankAccountNumber: string | null;
    amount: number;
};

export const PaymentTransferDetails = ({
    title,
    recipientName,
    bankAccountNumber,
    amount,
}: PaymentTransferDetailsProps) => {
    const bankAccountNumberText = bankAccountNumber
        ? parseBankAccountNumberIntoFormattedBankAccountNumber(bankAccountNumber)
        : EMPTY_VALUE_LABEL;
    const recipientNameText = recipientName || EMPTY_VALUE_LABEL;
    const institutionTransferAmountText = getFormattedPrice(amount);

    return (
        <DetailsFrameBase hideHeader>
            <DetailsRow>
                <DetailsColumn title={parentTexts.settlement.paymentTitle} span={12}>
                    <CopyContentText text={title} />
                </DetailsColumn>
            </DetailsRow>
            <DetailsRow>
                <DetailsColumn title={parentTexts.settlement.recipientName} span={12}>
                    <CopyContentText text={recipientNameText} />
                </DetailsColumn>
            </DetailsRow>
            <DetailsRow>
                <DetailsColumn title={parentTexts.settlement.bankAccountNumber} span={12}>
                    <CopyContentText text={bankAccountNumberText} />
                </DetailsColumn>
            </DetailsRow>
            <DetailsRow>
                <DetailsColumn title={parentTexts.settlement.amount} span={12}>
                    <CopyContentText text={institutionTransferAmountText} />
                </DetailsColumn>
            </DetailsRow>
        </DetailsFrameBase>
    );
};
