import dayjs from 'dayjs';
import { JournalsFiltersWithNoFilterValues, JournalsFilters, JournalStatus } from 'types';
import { isBeforeSchoolYear } from 'utils/isBeforeSchoolYear';
import { NO_FILTER_VALUE } from 'consts/filters/common/defaultFilterValue';

export const parseJournalsFiltersWithNoFilterValuesIntoJournalsFilters: (
    journalsFiltersWithNoFilterValues: JournalsFiltersWithNoFilterValues,
) => JournalsFilters = (journalsFiltersWithNoFilterValues) => {
    const { type, status, search, toYear, fromYear, teacherId } = journalsFiltersWithNoFilterValues;

    const currentDate = dayjs();
    const currentYear = currentDate.year();
    const isBeforeNewSchoolYear = isBeforeSchoolYear(currentDate, currentYear);

    const [fromYearDefaultValue, toYearDefaultValue] = isBeforeNewSchoolYear
        ? [currentYear - 1, currentYear]
        : [currentYear, currentYear + 1];
    const selectedOrDefaultStatusValue = status || 'ACTIVE';
    const selectedOrDefaultFromYearValue = fromYear || fromYearDefaultValue;
    const selectedOrDefaultToYearValue = toYear || toYearDefaultValue;

    const statusValue =
        status === NO_FILTER_VALUE
            ? undefined
            : (selectedOrDefaultStatusValue as JournalStatus | undefined);

    const fromYearValue =
        fromYear === NO_FILTER_VALUE
            ? undefined
            : (selectedOrDefaultFromYearValue as number | undefined);

    const toYearValue =
        toYear === NO_FILTER_VALUE
            ? undefined
            : (selectedOrDefaultToYearValue as number | undefined);

    return {
        type,
        status: statusValue,
        search,
        fromYear: fromYearValue,
        toYear: toYearValue,
        teacherId,
    };
};
