import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { journalFields } from 'consts/journal/journalFields';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { useDisabledDate } from 'hooks/useDisabledDate/useDisabledDate';
import { EmployeeId, JournalId } from 'types';

type StartAndEndDatePickersDisabledDatesProps = {
    isAddMode: boolean;
    selectedTeacherId?: EmployeeId;
    selectedJournalId?: JournalId;
    stringLessonStartDate?: string;
};

export const useStartAndEndDatePickersDisabledDates = ({
    isAddMode,
    selectedTeacherId,
    selectedJournalId,
    stringLessonStartDate,
}: StartAndEndDatePickersDisabledDatesProps) => {
    const [selectedJournalDates, setSelectedJournalDates] = useState<{
        startDate?: string;
        endDate?: string;
    }>();

    const { data: journalsData } = useAppQuery(
        'LESSONS_JOURNALS',
        [selectedTeacherId],
        () =>
            StaffHTTPService.employeeSchedule.getLessonsJournals({
                teacherId: selectedTeacherId,
                type: 'SPECIAL',
            }),
        {
            enabled: false,
        },
    );

    useEffect(() => {
        if (selectedJournalDates || !journalsData) {
            return;
        }
        const selectedJournalData = journalsData.find(
            (journalData) => journalData[journalFields.id] === selectedJournalId,
        );
        setSelectedJournalDates(selectedJournalData);
    }, [journalsData, selectedJournalId, selectedJournalDates]);

    const { startDate: stringSelectedJournalStartDate, endDate: stringSelectedJournalEndDate } =
        selectedJournalDates || { startDate: undefined, endDate: undefined };
    const defaultLessonsDateRange = {
        startDate: stringSelectedJournalStartDate,
        endDate: stringSelectedJournalEndDate,
    };

    const dayjsCurrentDate = dayjs();

    const parsedIntoDayjsSelectedJournalStartDate = stringSelectedJournalStartDate
        ? parseStringIntoDayjsDate(stringSelectedJournalStartDate)
        : undefined;
    const parsedIntoDayjsLessonStartDate = stringLessonStartDate
        ? parseStringIntoDayjsDate(stringLessonStartDate)
        : undefined;
    const parsedIntoStringCurrentDate = parseDayjsDateIntoString(dayjsCurrentDate, 'YYYY-MM-DD');

    const isLessonStartDateAfterCurrentDate = parsedIntoDayjsLessonStartDate?.isAfter(
        dayjsCurrentDate,
        'date',
    );
    const isJournalStartDateAfterCurrentDate = parsedIntoDayjsSelectedJournalStartDate?.isAfter(
        dayjsCurrentDate,
        'date',
    );

    const editModeRenderDisabledStartDateEndDateValue = isLessonStartDateAfterCurrentDate
        ? stringSelectedJournalEndDate
        : stringLessonStartDate;
    const editModeRenderDisabledEndDateStartDateValue = isJournalStartDateAfterCurrentDate
        ? stringSelectedJournalStartDate
        : parsedIntoStringCurrentDate;

    const renderDisabledStartDate = useDisabledDate(
        isAddMode
            ? defaultLessonsDateRange
            : {
                  startDate: stringSelectedJournalStartDate,
                  endDate: editModeRenderDisabledStartDateEndDateValue,
              },
    );
    const renderDisabledEndDate = useDisabledDate(
        isAddMode
            ? defaultLessonsDateRange
            : {
                  startDate: editModeRenderDisabledEndDateStartDateValue,
                  endDate: stringSelectedJournalEndDate,
              },
    );

    return {
        renderDisabledStartDate,
        renderDisabledEndDate,
        setSelectedJournalDates,
    };
};
