import styled, { createGlobalStyle, css } from 'styled-components';
import { AppSearchInput } from 'components/atoms/CommonAppComponents';

export const GlobalStyle = createGlobalStyle`
  .ant-select-dropdown:has(.institutionDropdown) {
      min-width: 220px !important;
  }
`;

export const StyledSearchInput = styled(AppSearchInput)`
    ${({ theme }) => css`
        margin-bottom: ${theme.sizes.marginSmall}px;
    `}
`;
