import { Radio, RadioGroupProps } from 'antd';
import { inputAndPickerBorderStyle } from 'consts/styles/inputAndPickerBorderStyle';
import styled, { css } from 'styled-components';
import { getTextStyle } from 'utils/getTextStyle';

const { Group } = Radio;
export type AppRadioGroupProps = RadioGroupProps & {
    sameSizeOptionButtons?: boolean;
    fullWidth?: boolean;
};

const StyledSegmented = styled(Group)<AppRadioGroupProps>`
    ${({ theme, sameSizeOptionButtons = true, fullWidth = true }) => css`
        &.ant-radio-group {
            width: ${fullWidth ? '100%' : 'auto'};
            display: flex;
        }

        .ant-radio-wrapper {
            ${getTextStyle('BodyMedium')};
            display: flex;
            align-items: center;

            .ant-radio {
                margin: 10px;
            }
        }

        .ant-radio-button-wrapper {
            display: flex;
            flex: ${sameSizeOptionButtons ? '1' : 'auto'};
            text-align: center;
            justify-content: center;
            height: unset;
            line-height: 1.2;
            padding: ${theme.sizes.marginSmall}px ${theme.sizes.marginNormal}px;
            ${getTextStyle('BodyMedium')}
            ${inputAndPickerBorderStyle}

            &.ant-radio-button-wrapper-checked {
                ${getTextStyle('BodyMediumSemiBold')};
                color: ${theme.colors.primary.primary10};
                border-color: ${theme.colors.primary.primary10};

                &:not(.ant-radio-button-wrapper-disabled)::before {
                    background-color: initial;
                }
            }

            &.ant-radio-button-wrapper-disabled {
                color: ${theme.colors.grayscale.gray7};
                border: 1px solid ${theme.colors.grayscale.gray4};
                background-color: ${theme.colors.grayscale.gray2};

                &.ant-radio-button-wrapper-checked {
                    background-color: ${theme.colors.grayscale.gray3};
                }
            }

            span:not(.ant-radio-button) {
                display: flex;
                align-items: center;
            }
        }
    `}
`;

export const AppRadioGroup = ({ ...props }: AppRadioGroupProps) => <StyledSegmented {...props} />;
