export const FIRST_DAY_OF_MONTH = 1;
export const LAST_DAY_OF_MONTH = 31;
export const TENTH_DAY_OF_MONTH = 10;
export const SCHOOL_YEAR_START_MONTH_INDEX = 8;
export const SCHOOL_YEAR_FINISH_MONTH_INDEX = 7;
export const MONDAY_INDEX = 1;
export const SATURDAY_INDEX = 6;
export const SUNDAY_INDEX = 7;
export const DAYJS_DAY_INDEX_OFFSET = 1;
export const ONE_MONTH_DIFFERENCE = 1;
