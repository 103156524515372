import dayjs from 'dayjs';
import { Filter } from 'components/molecules';
import { GetRemoteLessonsFilterKey, RemoteLessonsFilters } from 'types';
import { remoteLessonsTexts } from 'consts/text';

type YearsRangeFilterProps = {
    filtersValues: RemoteLessonsFilters;
    onChangeFilters: (filterValues: Partial<Record<GetRemoteLessonsFilterKey, string>>) => void;
};

export const YearsRangeFilter = ({ filtersValues, onChangeFilters }: YearsRangeFilterProps) => {
    const { startYear: startYearFilterValue, endYear: endYearFilterValue } = filtersValues;

    return (
        <Filter
            input={
                <Filter.RangePicker
                    picker="year"
                    value={[dayjs().year(startYearFilterValue), dayjs().year(endYearFilterValue)]}
                    allowClear={false}
                    onChange={(_, dateStrings) =>
                        onChangeFilters({
                            startYear: dateStrings[0],
                            endYear: dateStrings[1],
                        })
                    }
                />
            }
            label={remoteLessonsTexts.schoolYearsFilter}
            width="200px"
        />
    );
};
