import { commonTexts } from 'consts/text';

export const getDayLabel = (daysNumber: number) => {
    const pluralRules = new Intl.PluralRules('pl-PL');
    const grammaticalNumber = pluralRules.select(daysNumber);

    return grammaticalNumber === 'one'
        ? commonTexts.otherLabels.dayLabelsByDaysNumber.one
        : commonTexts.otherLabels.dayLabelsByDaysNumber.other;
};
