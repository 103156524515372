import { JournalId, LessonId } from 'types';

export const SPECIAL_ATTENDANCE = (journalId: JournalId, lessonId: LessonId) =>
    `/api/v1/journals/${journalId}/special-attendance/lessons/${lessonId}`;

export const SPECIAL_DAILY_ATTENDANCE = (journalId: JournalId) =>
    `/api/v1/journals/${journalId}/special-attendance/daily`;

export const SPECIAL_MONTHLY_ATTENDANCE = (journalId: JournalId) =>
    `/api/v1/journals/${journalId}/special-attendance/monthly`;
