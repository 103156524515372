import { ErrorDisplay, ErrorDisplayProps } from 'components/molecules';
import {
    TableBodyWrapperProps,
    TableBodyWrapper,
} from 'components/organisms/AppTable/components/TableStatesComponents/TableBodyWrapper/TableBodyWrapper';

export type TableErrorDisplayProps = Omit<TableBodyWrapperProps, 'children'> & ErrorDisplayProps;

export const TableErrorDisplay = ({ colSpan, ...props }: TableErrorDisplayProps) => (
    <TableBodyWrapper colSpan={colSpan}>
        <ErrorDisplay {...props} />
    </TableBodyWrapper>
);
