import { useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { IconArchive, IconEdit, IconEye, IconPrinter } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import { AppTable, ArchiveRestoreModal } from 'components/organisms';
import { JournalDTO, JournalId } from 'types';
import { journalFields } from 'consts/journal/journalFields';
import { usePageFilters } from 'hooks/usePageFilters/usePageFilters';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { Option } from 'components/atoms';
import { getJournalDetailsPathname } from 'utils/paths/getJournalDetailsPathname';
import { commonTexts, journalTexts, reportTexts } from 'consts/text/index';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { fileDownload } from 'utils/fileDownload';
import { useJournalAbilities } from 'abilities';
import { parseFilterParamsIntoJournalsFilters } from 'utils/parsers/journal/parseFilterParamsIntoJournalsFilters';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useJournalsPageNetworkManage } from './hooks/useJournalsPageNetworkManage';
import { JournalsPageFilters } from './components';
import { filters, getColumns } from './JournalsPage.utils';

export const JournalsPage = () => {
    const { statefulNavigate } = useNavigateWithState();
    const [downloadingReportsJournalIds, setDownloadingReportsJournalIds] = useState<JournalId[]>(
        [],
    );
    const { displayError } = useSimpleNotification();
    const [selectedToArchiveJournalId, setSelectedToArchiveJournalId] = useState<JournalId | null>(
        null,
    );
    const {
        filtersParams,
        searchFilterValue,
        handleChangeFilter,
        handleChangeFilters,
        handleClearFilters,
        handleSearchInput,
    } = usePageFilters({ filters });
    const {
        genericAbilities: { checkIfCanArchiveJournals },
        specialJournalsAbilities: {
            checkIfCanViewJournals: checkIfCanViewSpecialJournals,
            checkIfCanPrintJournals: checkIfCanPrintSpecialJournals,
            checkIfCanManageJournals: checkIfCanManageSpecialJournals,
        },
        groupJournalsAbilities: {
            checkIfCanViewJournals: checkIfCanViewGroupJournals,
            checkIfCanPrintJournals: checkIfCanPrintGroupJournals,
        },
    } = useJournalAbilities();

    const canManageSpecialJournals = checkIfCanManageSpecialJournals();
    const canArchiveJournals = checkIfCanArchiveJournals();
    const canPrintGroupJournals = checkIfCanPrintGroupJournals();
    const canPrintSpecialJournals = checkIfCanPrintSpecialJournals();
    const canViewSpecialJournals = checkIfCanViewSpecialJournals();
    const canViewGroupJournals = checkIfCanViewGroupJournals();

    const filtersValues = parseFilterParamsIntoJournalsFilters(filtersParams, searchFilterValue);

    const handleUnselectToArchiveJournalId = () => setSelectedToArchiveJournalId(null);

    const {
        journalsData,
        isJournalsDataInitialLoading,
        isJournalsDataRefetching,
        isArchiveJournalLoading,
        isJournalsDataError,
        archiveJournal,
    } = useJournalsPageNetworkManage({
        filtersValues,
        onJournalArchiveRequestFinish: handleUnselectToArchiveJournalId,
    });

    const handleViewJournal = ({ id, type }: JournalDTO) =>
        statefulNavigate(getJournalDetailsPathname(id, type));

    const handleArchiveJournalClick = (record: JournalDTO) =>
        setSelectedToArchiveJournalId(record.id);

    const handleArchiveJournalConfirm = () => {
        if (!selectedToArchiveJournalId) {
            return;
        }
        archiveJournal(selectedToArchiveJournalId);
    };

    const handlePrintJournal = (record: JournalDTO) => {
        setDownloadingReportsJournalIds([
            ...downloadingReportsJournalIds,
            record[journalFields.id],
        ]);
        StaffHTTPService.reports
            .getJournalReport(record[journalFields.id])
            .then((response) => {
                const binaryData = [];
                binaryData.push(response.data);
                const blob = new Blob(binaryData, { type: 'application/pdf' });
                const href = URL.createObjectURL(blob);

                fileDownload(href, `Raport ${record[journalFields.name]}.pdf`);
                URL.revokeObjectURL(href);
            })
            .catch((error) => {
                displayError(reportTexts.unableToFetchJournalReportError);
                console.error(error);
            })
            .finally(() =>
                setDownloadingReportsJournalIds((prevState) =>
                    prevState.filter((id) => id !== record[journalFields.id]),
                ),
            );
    };

    const navigateToJournalAdd = () => statefulNavigate(appPaths.app.journals.add);
    const navigateToJournalEdit = ({ id }: JournalDTO) =>
        statefulNavigate(generatePath(appPaths.app.journals.edit.pickedJournal, { journalId: id }));

    const checkIfVisibleDetailsOption = (journalTableItem: JournalDTO) =>
        (journalTableItem.type === 'GROUP' && canViewGroupJournals) ||
        (journalTableItem.type === 'SPECIAL' && canViewSpecialJournals);

    const checkIfVisiblePrintOption = (journalTableItem: JournalDTO) =>
        (journalTableItem.type === 'GROUP' && canPrintGroupJournals) ||
        (journalTableItem.type === 'SPECIAL' && canPrintSpecialJournals);

    const columns = useMemo(() => getColumns(filtersValues), [filtersValues]);

    const options: Option<JournalDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: handleViewJournal,
            Icon: <IconEye />,
            checkIfVisible: checkIfVisibleDetailsOption,
        },
        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToJournalEdit,
            checkIfVisible: (journalTableItem: JournalDTO) =>
                canManageSpecialJournals && journalTableItem[journalFields.type] !== 'GROUP',
            Icon: <IconEdit />,
        },
        {
            label: commonTexts.actionLabels.print,
            onClick: handlePrintJournal,
            Icon: <IconPrinter />,
            checkIfIsLoading: (journalTableItem: JournalDTO) =>
                downloadingReportsJournalIds.includes(journalTableItem[journalFields.id]),
            checkIfVisible: checkIfVisiblePrintOption,
        },
        {
            label: commonTexts.actionLabels.archive,
            onClick: handleArchiveJournalClick,
            checkIfVisible: (journalTableItem: JournalDTO) =>
                canArchiveJournals && journalTableItem[journalFields.status] !== 'ARCHIVED',
            Icon: <IconArchive />,
        },
    ];

    return (
        <>
            <ArchiveRestoreModal
                open={!!selectedToArchiveJournalId}
                title={journalTexts.journals.archiveModalTitle}
                confirmActionLoading={isArchiveJournalLoading}
                onOk={handleArchiveJournalConfirm}
                onCancel={handleUnselectToArchiveJournalId}
            />
            <AppTable
                title={journalTexts.journals.journalList}
                addButtonLabel={journalTexts.journals.addJournal}
                emptyInfo={journalTexts.journals.emptyJournalsTable}
                options={options}
                columns={columns}
                dataSource={journalsData}
                rowKey={({ id }) => id}
                isOptionsLoading={isArchiveJournalLoading}
                isError={isJournalsDataError}
                isDataInitialLoading={isJournalsDataInitialLoading}
                isDataRefetching={isJournalsDataRefetching}
                isHiddenAddButton={!canManageSpecialJournals}
                onAddButtonClick={navigateToJournalAdd}
                tableManageUtilsProps={{
                    searchInputProps: {
                        defaultValue: searchFilterValue,
                        onInput: debounce(handleSearchInput, DEBOUNCE_DELAY),
                        width: '25%',
                    },
                    additionalFilters: (
                        <JournalsPageFilters
                            type={filtersValues.type}
                            status={filtersValues.status}
                            fromYear={filtersValues.fromYear}
                            toYear={filtersValues.toYear}
                            onChangeFilter={handleChangeFilter}
                            onChangeFilters={handleChangeFilters}
                        />
                    ),
                    isDisplayedAsSeparateMenuOnMobile: true,
                    onClearFilters: handleClearFilters,
                }}
            />
        </>
    );
};
