import { LabelMapper, Repetition } from 'types';
import { timetableTexts } from 'consts/text';

export const lessonRepetitionLabels: LabelMapper<Repetition> = {
    EVERY_DAY: timetableTexts.lessons.lessonsRepetitionLabels.everyDay,
    WORKING_DAYS: timetableTexts.lessons.lessonsRepetitionLabels.workingDays,
    EVERY_MONTH: timetableTexts.lessons.lessonsRepetitionLabels.everyMonth,
    EVERY_2_WEEKS: timetableTexts.lessons.lessonsRepetitionLabels.everyTwoWeeks,
    EVERY_WEEK: timetableTexts.lessons.lessonsRepetitionLabels.everyWeek,
};
