import { generatePath } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import {
    AppColumnsType,
    AppColumnType,
    Attendance,
    JournalChildDailySpecialAttendance,
    JournalChildMonthlySpecialAttendanceDTO,
} from 'types';
import { DailySpecialAttendanceDisplay, JournalAttendanceFilters } from 'components/molecules';
import { useFirstAndLastJournalSchoolYears } from 'hooks/useFirstAndLastJournalSchoolYears/useFirstAndLastJournalSchoolYears';
import { appPaths } from 'consts/paths/paths';
import {
    childMonthlyAttendanceDTOFields,
    childSpecialMonthlyAttendanceDTOFields,
} from 'consts/attendance/childMonthlyAttendanceDTOFields';
import { commonTexts, journalTexts } from 'consts/text';
import { getFullName } from 'utils/getFullName';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { FULL_MONTH_FORMAT } from 'consts/dateFormats';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { AppText } from 'components/atoms';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { MonthlyAttendanceTableHeader } from 'pages/app/staff/journal/journalDetails/common/attendance/components';
import { FiltersContainer, StyledTable } from './SpecialJournalMonthlyAttendancePage.styled';

const childrenColumn: AppColumnType<JournalChildMonthlySpecialAttendanceDTO> = {
    title: commonTexts.dataLabels.children,
    dataIndex: childSpecialMonthlyAttendanceDTOFields.lessonChildDto,
    key: childSpecialMonthlyAttendanceDTOFields.lessonChildDto,
    render: (lessonChildDto: JournalChildMonthlySpecialAttendanceDTO['lessonChildDto']) =>
        getFullName(lessonChildDto.firstName, lessonChildDto.lastName),
    fixed: 'left',
    mobileWidth: '100px',
};

const summaryColumns: AppColumnsType<JournalChildMonthlySpecialAttendanceDTO> = [
    {
        title: (
            <AppText textType="BodyMediumSemiBold" margin={0}>
                {journalTexts.journalDetails.common.attendance.presentShort}
            </AppText>
        ),
        dataIndex: childMonthlyAttendanceDTOFields.presences,
        key: childMonthlyAttendanceDTOFields.presences,
        align: 'center',
        fixed: 'right',
    },
    {
        title: (
            <AppText textType="BodyMediumSemiBold" margin={0}>
                {journalTexts.journalDetails.common.attendance.absentShort}
            </AppText>
        ),
        dataIndex: childMonthlyAttendanceDTOFields.absences,
        key: childMonthlyAttendanceDTOFields.absences,
        align: 'center',
        fixed: 'right',
    },
];

export const SpecialJournalMonthlyAttendancePage = () => {
    const { journalId } = useJournalId();
    const { dateFilterValue, handleChangeDateFilter } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });
    const { statefulNavigate } = useNavigateWithState();
    const { firstAndLastJournalSchoolYears, isJournalDataFetching, isJournalDataError } =
        useFirstAndLastJournalSchoolYears({ journalId });

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const selectedDate = dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs();
    const selectedYear = selectedDate.year();
    const selectedMonth = getMonthFromDayjsMonth(selectedDate.month());
    const selectedFullMonth = selectedDate.format(FULL_MONTH_FORMAT);
    const currentAttendanceType: Attendance = 'MONTHLY';

    const {
        data: monthlyAttendanceData,
        isInitialLoading: isMonthlyAttendanceDataInitialLoading,
        isRefetching: isMonthlyAttendanceDataRefetching,
        isError: isMonthlyAttendanceDataError,
    } = useAppQuery(
        'SPECIAL_MONTHLY_ATTENDANCE',
        [journalId, selectedYear, selectedMonth],
        () =>
            StaffHTTPService.specialAttendance.getSpecialMonthlyAttendance(
                journalId,
                selectedYear,
                selectedMonth,
            ),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const handleAttendanceTypeChange = (selectedAttendanceType: Attendance) => {
        if (selectedAttendanceType === currentAttendanceType) {
            return;
        }
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.attendance.daily.base,
                { journalId },
            ),
        );
    };

    const columns = useMemo(() => {
        const daysInMonth = selectedDate.daysInMonth();
        const daysNumbersArray = Array.from({ length: daysInMonth }, (_, index) => index + 1);
        const dayAttendanceColumns = daysNumbersArray.reduce<
            AppColumnsType<JournalChildMonthlySpecialAttendanceDTO>
        >((allColumns, currentDayNumber) => {
            const columnTitle = currentDayNumber <= 9 ? `0${currentDayNumber}` : currentDayNumber;
            const column: AppColumnType<JournalChildMonthlySpecialAttendanceDTO> = {
                title: columnTitle,
                dataIndex: [childMonthlyAttendanceDTOFields.monthlyAttendance, currentDayNumber],
                key: currentDayNumber,
                align: 'center',
                render: (dailySpecialAttendance: JournalChildDailySpecialAttendance | undefined) =>
                    dailySpecialAttendance ? (
                        <DailySpecialAttendanceDisplay
                            dailySpecialAttendance={dailySpecialAttendance}
                        />
                    ) : (
                        EMPTY_VALUE_LABEL
                    ),
            };
            return allColumns.concat(column);
        }, []);
        const columnsWithChildrenColumn = [childrenColumn].concat(dayAttendanceColumns);
        return columnsWithChildrenColumn.concat(summaryColumns);
    }, [selectedDate]);

    return (
        <>
            <FiltersContainer>
                <JournalAttendanceFilters
                    attendanceType="MONTHLY"
                    date={dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs()}
                    firstSchoolYear={firstAndLastJournalSchoolYears?.firstYear}
                    lastSchoolYear={firstAndLastJournalSchoolYears?.lastYear}
                    isDatePickerDisabled={isJournalDataFetching}
                    onDateChange={handleChangeDateFilter}
                    onAttendanceTypeChange={handleAttendanceTypeChange}
                />
            </FiltersContainer>
            <MonthlyAttendanceTableHeader
                fullMonth={selectedFullMonth}
                year={selectedYear}
                totalPresences={monthlyAttendanceData?.presences ?? EMPTY_VALUE_LABEL}
                totalAbsences={monthlyAttendanceData?.absences ?? EMPTY_VALUE_LABEL}
            />
            <StyledTable
                emptyInfo={commonTexts.emptyInfo.noChildren}
                columns={columns}
                dataSource={monthlyAttendanceData?.childrenAttendance}
                rowKey={({ lessonChildDto }) => lessonChildDto.id}
                scroll={{ x: 'max-content' }}
                isTableHeaderHidden
                isTableManageUtilsHidden
                isTableColumnsTitlesVisibleOnMobile
                isError={isMonthlyAttendanceDataError || isJournalDataError}
                isDataInitialLoading={isMonthlyAttendanceDataInitialLoading}
                isDataRefetching={isMonthlyAttendanceDataRefetching}
                expandable={{ showExpandColumn: false }}
            />
        </>
    );
};
