import { JournalChildDailySpecialAttendance } from 'types';
import { AppFlex, AppText } from 'components/atoms';

type DailySpecialAttendanceDisplayProps = {
    dailySpecialAttendance: JournalChildDailySpecialAttendance;
};
export const DailySpecialAttendanceDisplay = ({
    dailySpecialAttendance,
}: DailySpecialAttendanceDisplayProps) => {
    const { attendances, absences } = dailySpecialAttendance;

    return (
        <AppFlex vertical>
            <AppText margin={0}>{`${attendances} ob`}</AppText>
            <AppText margin={0}>{`${absences} nb`}</AppText>
        </AppFlex>
    );
};
