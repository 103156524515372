import { SettlementOverallDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { weekDaysLabels } from 'consts/weekDaysLabels';
import { getFormattedDaysNumber } from 'utils/getFormattedDaysNumber';

export const parseSettlementOverallDTOIntoJsonObject: (
    settlementOverallDTO: SettlementOverallDTO,
) => object = (settlementOverallDTO) => {
    const { workingDays, recipientName, bankAccountNumber, paymentDeadline } = settlementOverallDTO;

    const parsedPaymentDeadline = paymentDeadline
        ? getFormattedDaysNumber(paymentDeadline)
        : EMPTY_VALUE_LABEL;
    const parsedBankAccountNumber = bankAccountNumber
        ? parseBankAccountNumberIntoFormattedBankAccountNumber(bankAccountNumber)
        : EMPTY_VALUE_LABEL;
    const parsedWorkingDays = workingDays.workingDays.map(
        (workingDay) => weekDaysLabels[workingDay],
    );

    return {
        [jsonObjectsTexts.settlementOverallDTO.fields.paymentDeadline]: parsedPaymentDeadline,
        [jsonObjectsTexts.settlementOverallDTO.fields.workingDays]: parsedWorkingDays,
        [jsonObjectsTexts.settlementOverallDTO.fields.bankAccountNumber]: parsedBankAccountNumber,
        [jsonObjectsTexts.settlementOverallDTO.fields.recipientName]:
            recipientName || EMPTY_VALUE_LABEL,
    };
};
