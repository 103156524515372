import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts, journalTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';

export const specialJournalAddEditErrorParser = (error: unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.GROUP_NAME_TAKEN) {
        return journalTexts.journals.journalNameTakenErrorMessage;
    }

    return commonTexts.errorMessages.unknown;
};
