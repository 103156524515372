import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts, timetableTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';

export const lessonAddEditErrorParser = (error: unknown) => {
    const { code } = getErrorMessageAndCode(error);

    switch (code) {
        case apiErrorCodes.LESSON_MODIFICATION_FORBIDDEN: {
            return timetableTexts.lessons.errorMessages.lessonModificationForbidden;
        }
        case apiErrorCodes.MODIFICATION_OF_LESSON_RANGE_WITHOUT_AFFECT_NEXT: {
            return timetableTexts.lessons.errorMessages.modificationOfLessonRangeWithoutAffectNext;
        }
        case apiErrorCodes.LESSON_WITH_CHILD_OUTSIDE_OF_GROUP_RANGE: {
            return timetableTexts.lessons.errorMessages.lessonWithChildOutsideOfGroupRange;
        }
        case apiErrorCodes.FORBIDDEN_MODIFICATION_OF_PAST_LESSON_RANGE: {
            return timetableTexts.lessons.errorMessages.forbiddenModificationOfPastLessonRange;
        }
        default: {
            return commonTexts.errorMessages.unknown;
        }
    }
};
