import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts, journalTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';

export const groupTopicAddEditErrorParser = (error: unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.INSTITUTION_NON_WORKING_DAY) {
        return journalTexts.journalDetails.common.errorMessages.institutionNonWorkingDay;
    }

    return commonTexts.errorMessages.unknown;
};
