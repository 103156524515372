import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { JournalId } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts, journalTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { queryClient } from 'services/reactQuery/queryClient';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';

type GroupJournalTopicsPageNetworkManageProps = {
    journalId: JournalId;
    date: string;
    onTopicRemoveRequestFinish: () => void;
};

export const useGroupJournalTopicsPageNetworkManage = ({
    journalId,
    date,
    onTopicRemoveRequestFinish,
}: GroupJournalTopicsPageNetworkManageProps) => {
    const { id: institutionId } = useCurrentInstitution();

    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: groupTopicsData,
        isInitialLoading: isGroupTopicsDataInitialLoading,
        isRefetching: isGroupTopicsDataRefetching,
        error: groupTopicsDataError,
    } = useAppQuery(
        'GROUP_TOPICS',
        [journalId, { date }],
        () => StaffHTTPService.groupTopics.getGroupTopics(journalId, date),
        { staleTime: FIVE_MINS_IN_MILLIS, retry: false },
    );

    const { mutate: removeGroupTopic, isLoading: isRemoveGroupTopicLoading } = useAppMutation(
        StaffHTTPService.groupTopics.removeGroupTopic,
        {
            key: ['REMOVE_GROUP_TOPIC'],
            onSuccess: async (_, variables) => {
                onTopicRemoveRequestFinish();
                await queryClient.invalidateQueries([
                    institutionId,
                    'GROUP_TOPIC',
                    variables.journalId,
                    variables.groupTopicId,
                ]);
                displaySuccess(
                    journalTexts.journalDetails.groupJournal.topics.topicRemoveSuccessNotification,
                );
            },
            onError: () => {
                onTopicRemoveRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [['GROUP_TOPICS', journalId]],
        },
    );

    return {
        groupTopicsData,
        isGroupTopicsDataInitialLoading,
        isGroupTopicsDataRefetching,
        isRemoveGroupTopicLoading,
        groupTopicsDataError,
        removeGroupTopic,
    };
};
