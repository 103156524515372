import { SorterResult, SortOrder } from 'antd/es/table/interface';
import { SelectOptionType, SettlementChildDTO, SettlementChildrenDtoFieldWithSorting } from 'types';
import { settlementChildrenDTOFields } from 'consts/settlementChildren/settlementChildrenDTOFields';
import { SortVariant } from './ChildrenSettlementPage.types';

export const sorterOptions: SelectOptionType<SortVariant, string>[] = [
    { label: 'Nazwisko i imię: rosnąco', value: SortVariant.NAME_ASC },
    { label: 'Nazwisko i imię: malejąco', value: SortVariant.NAME_DESC },
    { label: 'Grupa: rosnąco', value: SortVariant.GROUP_ASC },
    { label: 'Grupa: malejąco', value: SortVariant.GROUP_DESC },
    { label: 'Koniec umowy: rosnąco', value: SortVariant.SETTLEMENT_END_ASC },
    { label: 'Koniec umowy: malejąco', value: SortVariant.SETTLEMENT_END_DESC },
];

export const sortVariantIntoSorterResultMapper: Record<
    SortVariant,
    SorterResult<SettlementChildDTO>
> = {
    [SortVariant.NAME_ASC]: { order: 'ascend', field: settlementChildrenDTOFields.lastName },
    [SortVariant.NAME_DESC]: { order: 'descend', field: settlementChildrenDTOFields.lastName },
    [SortVariant.GROUP_ASC]: { order: 'ascend', field: settlementChildrenDTOFields.mainGroup },
    [SortVariant.GROUP_DESC]: { order: 'descend', field: settlementChildrenDTOFields.mainGroup },
    [SortVariant.SETTLEMENT_END_ASC]: {
        order: 'ascend',
        field: settlementChildrenDTOFields.settlementPeriodEnd,
    },
    [SortVariant.SETTLEMENT_END_DESC]: {
        order: 'descend',
        field: settlementChildrenDTOFields.settlementPeriodEnd,
    },
};

export const sorterResultIntoSortVariantMapper: Record<
    SettlementChildrenDtoFieldWithSorting,
    Record<Extract<SortOrder, 'descend' | 'ascend'>, SortVariant>
> = {
    lastName: { descend: SortVariant.NAME_DESC, ascend: SortVariant.NAME_ASC },
    mainGroup: { descend: SortVariant.GROUP_DESC, ascend: SortVariant.GROUP_ASC },
    settlementPeriodEnd: {
        descend: SortVariant.SETTLEMENT_END_DESC,
        ascend: SortVariant.SETTLEMENT_END_ASC,
    },
};
