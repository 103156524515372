import dayjs from 'dayjs';
import { Filter } from 'components/molecules';
import { GetJournalsFilterKey, JournalStatus, JournalType } from 'types';
import { commonTexts, journalTexts } from 'consts/text/index';

const typeOptions: { label: string; value: JournalType }[] = [
    { label: 'Grupowy', value: 'GROUP' },
    { label: 'Specjalistyczny', value: 'SPECIAL' },
];

const statusOptions: { label: string; value: JournalStatus }[] = [
    { label: 'Aktywne', value: 'ACTIVE' },
    { label: 'Zarchiwizowane', value: 'ARCHIVED' },
];

type JournalsPageFiltersProps = {
    type?: JournalType;
    status?: JournalStatus;
    fromYear?: number;
    toYear?: number;
    onChangeFilter: (
        filterKey: GetJournalsFilterKey,
        value?: string,
        withNoFilterAsNotSelectedFilterValue?: boolean,
    ) => void;
    onChangeFilters: (
        filterValues: Partial<Record<GetJournalsFilterKey, string>>,
        withNoFilterAsNotSelectedFilterValue?: boolean,
    ) => void;
};

export const JournalsPageFilters = ({
    type,
    status,
    fromYear,
    toYear,
    onChangeFilter,
    onChangeFilters,
}: JournalsPageFiltersProps) => (
    <>
        <Filter
            input={
                <Filter.Select
                    value={type}
                    options={typeOptions}
                    placeholder={commonTexts.actionLabels.select}
                    onChange={(value) => onChangeFilter('type', value)}
                />
            }
            label={journalTexts.journals.filters.journalType}
            width="15%"
        />
        <Filter
            input={
                <Filter.Select
                    value={status}
                    options={statusOptions}
                    placeholder={commonTexts.actionLabels.select}
                    onChange={(value) => onChangeFilter('status', value, true)}
                />
            }
            label={commonTexts.dataLabels.status}
            width="15%"
        />
        <Filter
            input={
                <Filter.RangePicker
                    picker="year"
                    value={[
                        fromYear ? dayjs().year(fromYear) : null,
                        toYear ? dayjs().year(toYear) : null,
                    ]}
                    onChange={(_, dateStrings) =>
                        onChangeFilters({ fromYear: dateStrings[0], toYear: dateStrings[1] }, true)
                    }
                />
            }
            label={journalTexts.journals.filters.schoolYear}
            width="15%"
        />
    </>
);
