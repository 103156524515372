import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { journalTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';
import { ErrorDisplayProps } from 'components/molecules';
import { institutionNonWorkingDayErrorStateConfig } from 'consts/journal/institutionNonWorkingDayErrorStateConfig';
import { NoScheduleDataStateIcon } from 'assets';

export const dailySpecialJournalAttendanceGetErrorParser: (error: unknown) => ErrorDisplayProps = (
    error,
) => {
    const { code } = getErrorMessageAndCode(error);

    switch (code) {
        case apiErrorCodes.INSTITUTION_NON_WORKING_DAY: {
            return institutionNonWorkingDayErrorStateConfig;
        }
        case apiErrorCodes.ATTENDANCE_NOT_AVAILABLE_FOR_ABSENT_TEACHER: {
            return {
                title: journalTexts.journalDetails.specialJournal.attendance
                    .notAvailableForAbsentTeacherAttendancesEmptyState.baseInfo,
                subTitle:
                    journalTexts.journalDetails.specialJournal.attendance
                        .notAvailableForAbsentTeacherAttendancesEmptyState.additionalInfo,
                icon: <NoScheduleDataStateIcon />,
            };
        }
        default: {
            return {};
        }
    }
};
