import { FiltersParams, JournalsFilters } from 'types';
import { parseFilterParamsIntoJournalsFiltersWithNoFilterValues } from 'utils/parsers/journal/parseFilterParamsIntoJournalsFiltersWithNoFilterValues';
import { parseJournalsFiltersWithNoFilterValuesIntoJournalsFilters } from 'utils/parsers/journal/parseJournalsFiltersWithNoFilterValuesIntoJournalsFilters';

export const parseFilterParamsIntoJournalsFilters: (
    filterParams: FiltersParams,
    searchFilterParam: string,
) => JournalsFilters = (filterParams, searchFilterParam) => {
    const parsedJournalsFiltersWithNoFilterValues =
        parseFilterParamsIntoJournalsFiltersWithNoFilterValues(filterParams, searchFilterParam);
    return parseJournalsFiltersWithNoFilterValuesIntoJournalsFilters(
        parsedJournalsFiltersWithNoFilterValues,
    );
};
