import { CreateTransactionDto, AddBillFormFields } from 'types';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

export const parseTransactionFormFieldsIntoCreateTransactionDto: (
    transactionFormFields: AddBillFormFields,
) => CreateTransactionDto = (transactionFormFields) => {
    const { date, description, ...restTransactionFormFields } = transactionFormFields;

    return {
        date: parseDayjsDateIntoString(date, 'YYYY-MM-DD'),
        description: description || null,
        ...restTransactionFormFields,
    };
};
